var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "expandHeader pt-16" },
    [
      _c(
        "v-container",
        [
          _c("the-layout-header", {
            attrs: {
              title: _vm.$t("home.create-wallet.title"),
              "subtitle-line-one": _vm.$t("home.create-wallet.subtitle-one"),
              "subtitle-line-two": _vm.$t("home.create-wallet.subtitle-two"),
              "route-obj": _vm.titleRoute,
              "has-link": "",
            },
          }),
          _c("div", [_c("router-view")], 1),
        ],
        1
      ),
      _c("div", { staticClass: "spacer-y-medium" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }