var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-textarea", {
    staticClass: "mew-text-area",
    attrs: {
      outlined: "",
      rules: _vm.rules,
      label: _vm.label,
      value: _vm.inputValue,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }