var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-bottom-sheet",
    {
      ref: "toast",
      attrs: {
        "hide-overlay": true,
        "retain-focus": false,
        persistent: _vm.persistent,
      },
      model: {
        value: _vm.showsToast,
        callback: function ($$v) {
          _vm.showsToast = $$v
        },
        expression: "showsToast",
      },
    },
    [
      _c(
        "v-sheet",
        {
          staticClass: "text-center",
          attrs: {
            tile: true,
            height: "80",
            color:
              _vm.toastType.toLowerCase() === _vm.toastTypes.info
                ? "white"
                : _vm.toastType,
          },
        },
        [
          _c(
            "v-container",
            { attrs: { "fill-height": "" } },
            [
              _c(
                "v-row",
                {
                  class: ["font-weight-medium", _vm.getRowClasses()],
                  attrs: { justify: "center", align: "center" },
                },
                [
                  _c(
                    "div",
                    { staticClass: "d-flex align-center" },
                    [
                      _vm.toastTypes.info !== _vm.toastType.toLowerCase()
                        ? _c(
                            "v-icon",
                            {
                              attrs: {
                                color:
                                  _vm.toastTypes.warning ===
                                  _vm.toastType.toLowerCase()
                                    ? "warning darken-1"
                                    : "white",
                              },
                            },
                            [_vm._v(" " + _vm._s(_vm.getIcon()) + " ")]
                          )
                        : _vm._e(),
                      _vm._v(" " + _vm._s(_vm.text) + " "),
                      _c(
                        "a",
                        {
                          class: _vm.getLinkClasses(),
                          on: { click: _vm.onClick },
                        },
                        [_vm._v(_vm._s(_vm.linkObj.title) + " ")]
                      ),
                      _vm._t("infoBtn"),
                    ],
                    2
                  ),
                  _vm.canClose
                    ? _c(
                        "v-icon",
                        {
                          staticClass: "close cursor-pointer",
                          attrs: { color: "titlePrimary" },
                          on: { click: _vm.close },
                        },
                        [_vm._v(" mdi-close ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }