var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mew-progress-bar full-width d-flex cursor-pointer" },
    _vm._l(_vm.data, function (section, idx) {
      return _c("div", {
        key: section + idx,
        class: [
          section.color,
          idx === 0 ? "left-border-radius" : "",
          idx === _vm.data.length - 1 ? "right-border-radius" : "",
        ],
        style: { height: "10px", width: section.percentage + "%" },
        attrs: { title: section.tooltip ? section.tooltip : "" },
      })
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }