var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-btn",
    {
      class: [_vm.buttonClasses, "mew-button"],
      attrs: {
        target: _vm.btnLink ? "_blank" : "",
        href: _vm.btnLink,
        color: _vm.buttonColor,
        disabled: _vm.disabled,
        loading: _vm.loading,
        depressed: "",
        outlined: _vm.hasOutline,
        text: _vm.isTransparent,
      },
    },
    [
      _c(
        "div",
        {
          class: [
            _vm.hasFullWidth ? "full-width" : "",
            "d-flex justify-center align-center",
          ],
        },
        [
          _vm.title
            ? _c(
                "span",
                { staticClass: "font-weight-regular reset-text-style" },
                [_vm._v(" " + _vm._s(_vm.title) + " ")]
              )
            : _vm._e(),
          _vm._t("default"),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }