var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "the-wrapper-dapp",
        {
          attrs: {
            "is-new-header": true,
            "dapp-img": _vm.headerImg,
            "banner-text": _vm.header,
            "tab-items": _vm.tabs,
            "active-tab": _vm.activeTab,
            "external-contents": "",
            "on-tab": _vm.tabChanged,
            "valid-networks": _vm.validNetworks,
          },
          scopedSlots: _vm._u([
            {
              key: "tabContent1",
              fn: function () {
                return [
                  _c(
                    "v-sheet",
                    {
                      staticClass: "px-3 py-8 py-md-13 mx-auto",
                      attrs: { "max-width": "700px", color: "transparent" },
                    },
                    [
                      _c("div", { staticClass: "mb-5" }, [
                        _c("div", { staticClass: "mew-heading-2 mb-8 ml-2" }, [
                          _vm._v(
                            " " + _vm._s(_vm.$t("rns.search-domain")) + " "
                          ),
                        ]),
                        _c(
                          "form",
                          {
                            on: {
                              submit: function ($event) {
                                $event.preventDefault()
                                return _vm.findDomain.apply(null, arguments)
                              },
                            },
                          },
                          [
                            _c(
                              "v-row",
                              { staticClass: "mx-0" },
                              [
                                _c(
                                  "v-col",
                                  { staticClass: "pr-0", attrs: { cols: "8" } },
                                  [
                                    _c("mew-input", {
                                      staticClass: "mr-3 flex-grow-1",
                                      attrs: {
                                        value: _vm.name,
                                        "has-clear-btn": true,
                                        rules: _vm.rules,
                                        label: _vm.$t(
                                          "rns.register.domain-name"
                                        ),
                                        placeholder:
                                          _vm.$t("rns.ph.three-char"),
                                        "error-messages": _vm.errorMessages,
                                      },
                                      on: { input: _vm.setName },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { staticClass: "pl-0", attrs: { cols: "4" } },
                                  [
                                    _c("mew-button", {
                                      attrs: {
                                        loading: _vm.loading,
                                        disabled:
                                          !_vm.name ||
                                          (_vm.name && _vm.name.length < 3) ||
                                          _vm.loading ||
                                          (_vm.name &&
                                            _vm.name.split(".").length > 2),
                                        "has-full-width": true,
                                        "btn-size": "xlarge",
                                        title: _vm.$t("rns.register.name"),
                                      },
                                      nativeOn: {
                                        click: function ($event) {
                                          return _vm.findDomain.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]
                  ),
                ]
              },
              proxy: true,
            },
            {
              key: "tabContent2",
              fn: function () {
                return [
                  _c(
                    "v-sheet",
                    {
                      staticClass: "px-3 py-8 py-md-13 mx-auto",
                      attrs: { "max-width": "500px", color: "transparent" },
                    },
                    [
                      _c(
                        "div",
                        [
                          _c("rns-reverse-lookup", {
                            attrs: {
                              address: _vm.address,
                              "rns-manager": _vm.nameHandler,
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _vm._v(
            " ===================================================================================== Reverse Lookup - Tab 2 ===================================================================================== --> "
          ),
        ]
      ),
      _vm.onRegister
        ? _c("module-register-domain", {
            ref: "registerDomain",
            attrs: {
              "on-register": _vm.onRegister,
              close: _vm.closeRegister,
              register: _vm.register,
              "not-enough-funds": _vm.notEnoughFunds,
              "loading-commit": _vm.loadingCommit,
              "loading-reg": _vm.loadingReg,
              committed: _vm.committed,
              "minimum-age": _vm.minimumAge,
              commit: _vm.commit,
              "no-funds-for-reg-fees": _vm.noFundsForRegFees,
              "commit-fee-in-eth": _vm.commitFeeInEth,
              "commit-fee-in-wei": _vm.commitFeeInWei,
              "commit-fee-usd": _vm.commitFeeUsd,
              name: _vm.name,
              "checking-domain-avail": _vm.loading,
              "get-rent-price": _vm.getRentPrice,
              "waiting-for-reg": _vm.waitingForReg,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }