var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-expansion-panels",
    {
      class: _vm.expandPanelsClasses,
      attrs: {
        value: _vm.expandIdxArr,
        accordion: _vm.isAccordion,
        multiple: "",
        flat: true,
      },
    },
    _vm._l(_vm.panelItems, function (item, i) {
      return _c(
        "v-expansion-panel",
        { key: i, class: !_vm.isAccordion ? "mb-2" : "" },
        [
          _c(
            "v-expansion-panel-header",
            {
              class: ["pa-5"],
              attrs: {
                color: _vm.isGreyTheme ? "bgWalletBlock" : "inputPrimary",
              },
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex align-center mew-body font-weight-medium textDark--text",
                },
                [_vm._v(" " + _vm._s(item.name) + " ")]
              ),
              _c(
                "div",
                {
                  staticClass: "d-flex align-center justify-center",
                  attrs: { slot: "actions" },
                  slot: "actions",
                },
                [
                  _c(
                    "span",
                    {
                      class: [
                        "mew-body mr-5 text-right",
                        _vm.isGreyTheme
                          ? "textMedium--text"
                          : "textLight--text",
                      ],
                    },
                    [_vm._v(_vm._s(item.toggleTitle))]
                  ),
                  !_vm.isExpanded(i)
                    ? _c(
                        "v-icon",
                        {
                          attrs: {
                            color: _vm.isGreyTheme ? "textMedium" : "textLight",
                          },
                        },
                        [_vm._v(" mdi-chevron-down ")]
                      )
                    : _c(
                        "v-icon",
                        {
                          attrs: {
                            color: _vm.isGreyTheme ? "textMedium" : "textLight",
                          },
                        },
                        [_vm._v(" mdi-chevron-down ")]
                      ),
                ],
                1
              ),
            ]
          ),
          _c(
            "v-expansion-panel-content",
            { attrs: { color: "inputPrimary" } },
            [_vm._t("panelBody" + (i + 1))],
            2
          ),
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }