var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        "max-width": _vm.maxWidth,
        value: _vm.show,
        fullscreen: _vm.scrollable ? _vm.$vuetify.breakpoint.xs : false,
        "content-class": "ma-0",
        scrollable: _vm.scrollable,
      },
      on: { "click:outside": _vm.handleClickOutside },
    },
    [
      _c(
        "v-card",
        { staticClass: "pa-0", attrs: { color: "bgWalletBlock" } },
        [
          _c(
            "div",
            { class: _vm.title ? "pt-0" : "pt-5" },
            [
              !_vm.hideCloseBtn
                ? _c(
                    "v-btn",
                    { staticClass: "header-close-icon", attrs: { icon: "" } },
                    [
                      _c(
                        "v-icon",
                        {
                          attrs: {
                            size: "x-large",
                            color: "grey cursor--pointer",
                          },
                          on: { click: _vm.leftBtn.method },
                        },
                        [_vm._v(" mdi-close ")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm.title
            ? _c(
                "v-card-title",
                {
                  class: [
                    "justify-center px-5 px-md-7",
                    _vm.hasBodyContent ? "py-5 py-md-8" : "pb-0 pt-5 pt-md-8",
                  ],
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "break-word text-center",
                      class: _vm.largeTitle ? "mew-subtitle" : "mew-heading-2",
                    },
                    [_vm._v(" " + _vm._s(_vm.title) + " ")]
                  ),
                ]
              )
            : _vm._e(),
          _vm.hasBodyContent
            ? _c(
                "v-card-text",
                { class: [_vm.hasPadding ? "py-3 px-5 px-md-7" : "pa-0"] },
                [_vm._t("default")],
                2
              )
            : _vm._e(),
          _vm.hasButtons
            ? _c(
                "v-card-actions",
                { staticClass: "py-5 py-md-8" },
                [
                  _c(
                    "v-row",
                    {
                      staticClass: "pa-0",
                      attrs: { justify: "space-around", dense: "" },
                    },
                    [
                      _c(
                        "v-col",
                        {
                          class: !_vm.rightBtn ? "text-left" : "text-right",
                          attrs: {
                            cols: "12",
                            sm: !_vm.rightBtn ? "12" : "6",
                            order: !_vm.rightBtn ? "1" : "2",
                            "order-sm": "1",
                          },
                        },
                        [
                          _c("mew-button", {
                            attrs: {
                              "btn-style": "outline",
                              "btn-size": "xlarge",
                              "color-theme": _vm.leftBtn.color || "primary",
                              title: _vm.leftBtn.text,
                              "has-full-width": !_vm.rightBtn
                                ? true
                                : _vm.$vuetify.breakpoint.xs,
                            },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.leftBtn.method.apply(null, arguments)
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _vm.rightBtn
                        ? _c(
                            "v-col",
                            {
                              staticClass: "text-left",
                              attrs: {
                                cols: "12",
                                sm: "6",
                                order: "1",
                                "order-sm": "2",
                              },
                            },
                            [
                              _c("mew-button", {
                                attrs: {
                                  "btn-size": "xlarge",
                                  "color-theme":
                                    _vm.rightBtn.color || "primary",
                                  title: _vm.rightBtn.text,
                                  disabled: !_vm.rightBtn.enabled,
                                  "has-full-width": _vm.$vuetify.breakpoint.xs,
                                },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.rightBtn.method.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }