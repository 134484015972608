var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-bottom-sheet",
    {
      attrs: {
        value: _vm.isOverlayShown,
        fullscreen: true,
        persistent: "",
        "content-class": "mew-overlay",
      },
    },
    [
      _c(
        "v-sheet",
        {
          staticClass: "mew-overlay-container d-flex align-center flex-column",
          attrs: { height: "100%", color: "emerald100" },
        },
        [
          _vm.isMobile
            ? _c(
                "div",
                {
                  staticClass:
                    "mobile-title-block pa-3 d-flex align-center justify-space-between full-width",
                },
                [
                  _c(
                    "div",
                    { staticStyle: { "min-width": "36px" } },
                    [
                      _vm.back
                        ? _c(
                            "v-btn",
                            {
                              attrs: { icon: "", color: "textBlack2" },
                              on: { click: _vm.back },
                            },
                            [
                              _c("v-icon", { attrs: { size: "24" } }, [
                                _vm._v(" mdi-arrow-left "),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "h3",
                    {
                      staticClass: "titlePrimary--text mx-4 text-center",
                      staticStyle: { "line-height": "21px" },
                    },
                    [_vm._v(" " + _vm._s(_vm.title) + " ")]
                  ),
                  _c(
                    "div",
                    { staticStyle: { "min-width": "36px" } },
                    [
                      _vm.close
                        ? _c(
                            "v-btn",
                            {
                              attrs: { icon: "", color: "textBlack2" },
                              on: { click: _vm.close },
                            },
                            [
                              _c(
                                "v-icon",
                                { attrs: { size: "24", color: "textBlack2" } },
                                [_vm._v(" mdi-close ")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
          _vm.back && !_vm.isMobile
            ? _c(
                "v-btn",
                {
                  class: [
                    "d-flex action-btn align-center pa-3",
                    _vm.isMobile ? "mt-3 ml-n1 mobile-btn" : "mt-4 ml-1",
                  ],
                  attrs: {
                    top: "",
                    left: "",
                    absolute: "",
                    text: "",
                    rounded: "",
                    "min-width": "48",
                    "min-height": "48",
                    color: "textBlack2",
                    fab: "",
                  },
                  on: { click: _vm.back },
                },
                [
                  _c("v-icon", { attrs: { size: "24" } }, [
                    _vm._v(" mdi-arrow-left "),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm.close && !_vm.isMobile
            ? _c(
                "v-btn",
                {
                  class: [
                    "d-flex action-btn align-center pa-3",
                    ,
                    _vm.isMobile ? "mt-3 mr-n1 mobile-btn" : "mt-4 mr-1",
                  ],
                  attrs: {
                    fab: "",
                    top: "",
                    right: "",
                    absolute: "",
                    text: "",
                    color: "textBlack2",
                  },
                  on: { click: _vm.close },
                },
                [
                  _c("v-icon", { attrs: { size: "24", color: "textBlack2" } }, [
                    _vm._v(" mdi-close "),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-container",
            {
              class: ["ma-0 pa-0", _vm.isMobile ? "full-height" : ""],
              attrs: { fluid: "" },
            },
            [
              _c(
                "v-row",
                {
                  staticClass:
                    "ma-0 pa-0 d-flex align-center justify-center flex-column",
                },
                [
                  _c(
                    "v-sheet",
                    {
                      class: [
                        "white-sheet-container",
                        _vm.isMobile ? "mt-0" : "mt-4",
                      ],
                      attrs: {
                        width: _vm.isMobile ? "100%" : _vm.sheetWidth,
                        height: "100%",
                        color: "bgWalletBlock",
                      },
                    },
                    [
                      !_vm.isMobile
                        ? _c(
                            "div",
                            {
                              class: [
                                "text-center pa-8",
                                _vm.isMobile && !_vm.back ? "pl-0" : "",
                              ],
                            },
                            [
                              _c(
                                "div",
                                {
                                  class: [
                                    "titlePrimary--text",
                                    _vm.isMobile
                                      ? "mew-heading-2 ml-3"
                                      : _vm.isMobile && !_vm.back
                                      ? "mew-heading-2 ml-4"
                                      : "mew-subtitle",
                                  ],
                                },
                                [_vm._v(" " + _vm._s(_vm.title) + " ")]
                              ),
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        {
                          class: [
                            "d-flex flex-column align-center justify-center",
                            _vm.isMobile
                              ? "px-3 pb-6 mobile-content"
                              : "px-8 pb-8",
                          ],
                        },
                        [_vm._t("default")],
                        2
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                {
                  staticClass: "ma-0 py-8 textMedium--text",
                  attrs: { justify: "center" },
                },
                [
                  _vm._v(" " + _vm._s(_vm.footer.text) + " "),
                  _vm.footer && _vm.footer.linkTitle && _vm.footer.link
                    ? _c(
                        "a",
                        {
                          staticClass: "cursor-pointer font-weight-medium ml-1",
                          attrs: {
                            rel: "noopener noreferrer",
                            href: _vm.footer.link,
                            target: "_blank",
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.footer.linkTitle) + " ")]
                      )
                    : _vm._e(),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }