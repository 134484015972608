var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mx-auto", staticStyle: { "max-width": "650px" } },
    _vm._l(_vm.buttons, function (btn, key) {
      return _c(
        "div",
        { key: key, staticClass: "position--relative" },
        [
          btn.official
            ? _c(
                "div",
                {
                  staticClass: "chip-official d-flex align-center",
                  class: _vm.isMobile
                    ? "note-position-mobile"
                    : "note-position",
                },
                [
                  _c(
                    "v-icon",
                    {
                      staticClass: "mr-1",
                      attrs: { color: "whiteAlways", size: "15px" },
                    },
                    [_vm._v(" mdi-shield-check ")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "font-weight-medium letter-spacing--initial line-height--initial",
                    },
                    [_vm._v(" Official ")]
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "mew-button",
            {
              staticClass: "mb-5 py-6",
              staticStyle: { height: "initial", "min-height": "157px" },
              attrs: {
                "has-full-width": "",
                "color-theme": btn.color,
                "btn-style": btn.style === "outline" ? "outline" : "",
              },
              nativeOn: {
                click: function ($event) {
                  return btn.fn.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "width--full d-flex align-center text-left" },
                [
                  btn.icon && !_vm.isMobile
                    ? _c("img", {
                        staticClass: "ml-5 mr-6",
                        staticStyle: { height: "70px" },
                        attrs: { src: btn.icon, alt: btn.alt },
                      })
                    : _vm._e(),
                  _c("div", { staticClass: "px-3" }, [
                    _c("div", { staticClass: "d-flex align-center" }, [
                      btn.icon && _vm.isMobile
                        ? _c("img", {
                            staticClass: "mr-4",
                            staticStyle: { height: "40px" },
                            attrs: { src: btn.icon, alt: btn.alt },
                          })
                        : _vm._e(),
                      _c(
                        "div",
                        {
                          staticClass:
                            "mew-heading-2 break-word letter-spacing--initial",
                        },
                        [_vm._v(" " + _vm._s(btn.title) + " ")]
                      ),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass:
                          "mew-heading-4 reset-subtitle break-word letter-spacing--initial text-transform--none mt-4",
                      },
                      [_vm._v(" " + _vm._s(btn.subtitle) + " ")]
                    ),
                  ]),
                ]
              ),
            ]
          ),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }