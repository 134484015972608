var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mew-components--mew-notification" }, [
    _c(
      "div",
      { staticClass: "notification-container" },
      [
        _c(
          "v-expansion-panels",
          { attrs: { flat: "" } },
          [
            _c(
              "v-expansion-panel",
              {
                class: _vm.getBorderClasses(
                  _vm.notification.status.value.toLowerCase(),
                  _vm.notification.read
                ),
              },
              [
                _c(
                  "v-expansion-panel-header",
                  {
                    staticClass: "px-2 py-0",
                    attrs: { "hide-actions": "", color: _vm.backgroundColor },
                  },
                  [
                    _c(
                      "v-container",
                      { staticClass: "px-0", attrs: { fluid: "" } },
                      [
                        _c(
                          "v-row",
                          { attrs: { dense: "" } },
                          [
                            _c(
                              "v-col",
                              {
                                staticClass: "d-flex align-center",
                                attrs: { cols: "8" },
                              },
                              [
                                _vm.$vuetify.breakpoint.smAndUp &&
                                !_vm.notification.read &&
                                _vm.showIndicator
                                  ? _c("div", {
                                      class: [
                                        _vm.getClasses(
                                          _vm.notification.status.value.toLowerCase()
                                        ),
                                        "indicator",
                                        "ml-2",
                                        "d-none",
                                        "d-sm-flex",
                                      ],
                                    })
                                  : _vm._e(),
                                !_vm.isSwap
                                  ? _c("mew-blockie", {
                                      staticClass: "d-flex ml-2",
                                      attrs: {
                                        width: "24px",
                                        height: "24px",
                                        address: _vm.notification.from.value,
                                      },
                                    })
                                  : _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex flex-column currency-symbol ml-2",
                                      },
                                      [
                                        _c("MewTokenContainer", {
                                          attrs: {
                                            img: _vm.notification.fromObj.icon
                                              ? _vm.notification.fromObj.icon
                                              : _vm.ethTokenPlaceholder,
                                            "token-border": "",
                                          },
                                        }),
                                        _c("MewTokenContainer", {
                                          staticClass: "overlap",
                                          attrs: {
                                            img: _vm.notification.toObj.icon,
                                            "token-border": "",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                _c(
                                  "div",
                                  {
                                    class: [
                                      "detail-container pr-1",
                                      _vm.isSwap ? "ml-5" : "ml-2",
                                    ],
                                  },
                                  [
                                    !_vm.isSwap
                                      ? _c("div", [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "caption font-weight-medium d-flex",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.notification.from.string
                                                  ) +
                                                  ": "
                                              ),
                                              _c("mew-transform-hash", {
                                                staticClass: "ml-1 detail-hash",
                                                attrs: {
                                                  hash: _vm.notification.from
                                                    .value,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "caption font-weight-medium d-flex",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.notification.amount
                                                      .string
                                                  ) +
                                                  ": " +
                                                  _vm._s(
                                                    _vm.notification.amount
                                                      .value
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ])
                                      : _c("div", [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "caption font-weight-medium d-flex",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.notification.to.string
                                                  ) +
                                                  ": "
                                              ),
                                              _c("mew-transform-hash", {
                                                staticClass: "ml-1 detail-hash",
                                                attrs: {
                                                  hash: _vm.notification.toObj
                                                    .to,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "caption mew-heading-2 mr-3 align-center",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "mr-1 line-height-initial d-flex amount-font",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(_vm.fromAmount) +
                                                      " "
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "textPrimary--text ml-1",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.notification
                                                            .fromObj.currency
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "amount-container amount-font mr-3 d-flex line-height-initial",
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      staticClass:
                                                        "subtitle-1 mr-1",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " mdi-arrow-right "
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(_vm.toAmount) +
                                                      " "
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "textPrimary--text ml-1",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.notification.toObj
                                                            .currency
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ]),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "text-right pr-2",
                                attrs: { cols: "4" },
                              },
                              [
                                _c("mew-badge", {
                                  attrs: {
                                    "badge-title": _vm.notification.type.string,
                                    "badge-type": _vm.getBadgeType,
                                  },
                                }),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "caption mt-1 textPrimary--text font-weight-medium",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.notification.timestamp.value
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "v-expansion-panel-content",
                  {
                    staticClass: "pa-0",
                    attrs: { color: _vm.backgroundColor },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "expanded-container capitalize" },
                      [
                        _c(
                          "v-container",
                          _vm._l(_vm.getDetails, function (detail, idx) {
                            return _c(
                              "v-row",
                              { key: idx },
                              [
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "textPrimary--text",
                                    attrs: { cols: "6" },
                                  },
                                  [_vm._v(" " + _vm._s(detail.string) + ": ")]
                                ),
                                !_vm.isHash(detail.string)
                                  ? _c(
                                      "v-col",
                                      {
                                        class: [
                                          _vm.getClasses(detail.value) +
                                            "--text",
                                          "text-right",
                                        ],
                                        attrs: { cols: "6" },
                                      },
                                      [_vm._v(" " + _vm._s(detail.value) + " ")]
                                    )
                                  : _vm._e(),
                                _vm.isHash(detail.string)
                                  ? _c(
                                      "v-col",
                                      {
                                        staticClass: "text-right",
                                        attrs: { cols: "6" },
                                      },
                                      [
                                        _c("mew-tooltip", {
                                          attrs: {
                                            "content-class": "tooltip-inner",
                                            color: "titlePrimary--text",
                                            "max-width": "550px",
                                            "hide-icon": "",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activatorSlot",
                                                fn: function (ref) {
                                                  var on = ref.on
                                                  return [
                                                    _c(
                                                      "a",
                                                      _vm._g(
                                                        {
                                                          attrs: {
                                                            href: detail.link,
                                                            target: "_blank",
                                                          },
                                                        },
                                                        on
                                                      ),
                                                      [
                                                        _c(
                                                          "mew-transform-hash",
                                                          {
                                                            attrs: {
                                                              hash: detail.value,
                                                            },
                                                          }
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                              },
                                              {
                                                key: "contentSlot",
                                                fn: function () {
                                                  return [
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(detail.value)
                                                      ),
                                                    ]),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }