var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dapps-stakewise-stake pt-8 pb-13 px-3 pa-sm-15" },
    [
      _c(
        "v-row",
        { attrs: { align: "center", justify: "center" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "10" } },
            [
              _c("mew-warning-sheet", {
                staticClass: "mb-5",
                attrs: {
                  title: "StakeWise V3 is now live on\n      mainnet",
                  description:
                    "The Stakewise integration on MEW is no longer accepting deposits. You can still unstake your ETH by redeeming your sETH2 and rETH2 tokens.",
                  bottom: false,
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            {
              class: _vm.$vuetify.breakpoint.smAndDown ? "my-10" : "",
              attrs: {
                order: _vm.$vuetify.breakpoint.smAndDown ? "last" : "",
                cols: "12",
                md: "10",
              },
            },
            [
              _c(
                "mew-sheet",
                { staticClass: "pa-15" },
                [
                  _c("div", { staticClass: "mew-heading-4 font-weight-bold" }, [
                    _vm._v(
                      " MEW will no longer be accepting new deposits in the integrated Stakewise app. Unstaking continues to be available. Users who staked with Stakewise via MEW have the following options: "
                    ),
                  ]),
                  _c("div", { staticClass: "mt-6" }, [
                    _c("ul", { staticClass: "textMedium--text" }, [
                      _c("li", { staticClass: "mb-2" }, [
                        _c(
                          "a",
                          {
                            attrs: {
                              href: "https://help.myetherwallet.com/en/articles/6136823-stake-your-eth-using-stakewise",
                              target: "_blank",
                            },
                          },
                          [_vm._v("Unstake your ETH from Stakewise ")]
                        ),
                        _vm._v(
                          " in MEW and re-stake via one of our staking partners - Staked.us, for full validators staking, and Coinbase, for staking any amount with no minimum. "
                        ),
                      ]),
                      _c("li", { staticClass: "mb-2" }, [
                        _vm._v(" Connect your wallet to the "),
                        _c(
                          "a",
                          {
                            attrs: {
                              href: "https://app.stakewise.io/",
                              target: "_blank",
                            },
                          },
                          [_vm._v("Stakewise web app")]
                        ),
                        _vm._v(
                          " to view and continue managing your stake there. "
                        ),
                      ]),
                    ]),
                  ]),
                  _c("v-divider", { staticClass: "mt-9 mb-8" }),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c("stakewise-staking", {
                            staticClass: "mb-4",
                            attrs: {
                              "tx-fee": _vm.txFee,
                              "has-enough-balance": _vm.hasEnoughBalance,
                            },
                            on: {
                              "set-max": _vm.setMax,
                              scroll: _vm.scroll,
                              "redeem-to-eth": _vm.redeemToEth,
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _vm.isEthNetwork
                            ? _c("stakewise-rewards", {
                                attrs: { "tx-fee": _vm.txFee },
                                on: {
                                  "set-max": _vm.setMax,
                                  scroll: _vm.scroll,
                                  "redeem-to-eth": _vm.redeemToEth,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }