var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-select", {
    staticClass: "mew-select rounded-lg",
    attrs: {
      color: "primary",
      "append-icon": "mdi-chevron-down",
      items: _vm.selectItems,
      "item-text": "name",
      "item-value": "value",
      label: _vm.label,
      "hide-selected": _vm.loading,
      disabled: _vm.disabled,
      "error-messages": _vm.errorMessages,
      "return-object": "",
      "menu-props": { bottom: true, offsetY: true, maxHeight: "419px" },
      outlined: "",
    },
    on: { click: _vm.onClick },
    scopedSlots: _vm._u([
      {
        key: "message",
        fn: function (item) {
          return [
            _c("span", { staticClass: "mew-label" }, [
              _vm._v(" " + _vm._s(item.message) + " "),
              _vm.buyMoreStr
                ? _c(
                    "a",
                    {
                      staticClass: "mew-label",
                      attrs: { rel: "noopener noreferrer" },
                      on: { click: _vm.emitBuyMore },
                    },
                    [_vm._v(" " + _vm._s(_vm.buyMoreStr) + " ")]
                  )
                : _vm._e(),
            ]),
          ]
        },
      },
      {
        key: "prepend-item",
        fn: function () {
          return [
            _vm.hasFilter || _vm.isCustom
              ? _c("v-text-field", {
                  ref: "filterTextField",
                  staticClass: "px-2 mew-select-search d-flex align-center",
                  attrs: {
                    height: "35",
                    color: "disabled",
                    placeholder: _vm.filterPlaceholder,
                    flat: "",
                    solo: "",
                    dense: "",
                    "hide-details": "",
                    "prepend-inner-icon": "mdi-magnify",
                  },
                  model: {
                    value: _vm.search,
                    callback: function ($$v) {
                      _vm.search = $$v
                    },
                    expression: "search",
                  },
                })
              : _vm._e(),
          ]
        },
        proxy: true,
      },
      {
        key: "selection",
        fn: function (ref) {
          var item = ref.item
          return [
            item.selectLabel
              ? _c(
                  "div",
                  {
                    staticClass:
                      "d-flex align-center flex-row justify-space-between full-width basic--text",
                  },
                  [
                    _c("span", [_vm._v(_vm._s(item.text))]),
                    _vm.loading
                      ? _c("v-skeleton-loader", {
                          staticClass: "no-pointer-events",
                          attrs: { type: "chip" },
                        })
                      : _vm._e(),
                    !_vm.loading && item.imgs && !_vm.normalDropdown
                      ? _c(
                          "div",
                          { staticClass: "flex-row d-flex align-center" },
                          [
                            _vm._l(item.imgs, function (url, idx) {
                              return _c("mew-token-container", {
                                key: url + idx,
                                staticClass: "label-token-img",
                                attrs: {
                                  loading: _vm.loading,
                                  img: url,
                                  size: "small",
                                },
                              })
                            }),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "total-token-placeholder inputBorder d-flex align-center justify-center mew-caption",
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "textSecondary--text" },
                                  [_vm._v("+" + _vm._s(item.total))]
                                ),
                              ]
                            ),
                          ],
                          2
                        )
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            !item.selectLabel
              ? _c(
                  "div",
                  { staticClass: "d-flex align-center justify-center" },
                  [
                    !_vm.normalDropdown
                      ? _c("mew-token-container", {
                          staticClass: "ml-1 flex-shrink-0",
                          attrs: {
                            loading: _vm.loading,
                            img: item.img,
                            name: item.name || item,
                            size: "small",
                          },
                        })
                      : _vm._e(),
                    _c(
                      "span",
                      {
                        staticClass: "mt-1 ml-2 basic--text",
                        class: _vm.noCapitalize ? "" : "text-capitalize",
                      },
                      [
                        _vm._v(_vm._s(item.name ? item.name : item) + " "),
                        item.subtext
                          ? _c(
                              "span",
                              {
                                staticClass: "searchText--text",
                                class: _vm.noCapitalize
                                  ? ""
                                  : "text-capitalize",
                              },
                              [_vm._v("- " + _vm._s(item.subtext))]
                            )
                          : _vm._e(),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]
        },
      },
      {
        key: "item",
        fn: function (data) {
          return [
            _vm.loading
              ? _c("v-skeleton-loader", {
                  staticClass: "no-pointer-events mew-select-loading",
                  attrs: { "min-width": "100%", type: "list-item-avatar" },
                })
              : _vm._e(),
            !_vm.isCustom && !_vm.loading
              ? _c(
                  "div",
                  { staticClass: "d-flex align-center justify-center" },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "ml-2 mt-1",
                        class: _vm.noCapitalize ? "" : "text-capitalize",
                      },
                      [
                        _vm._v(
                          _vm._s(data.item.name ? data.item.name : data.item) +
                            " "
                        ),
                        data.item.subtext
                          ? _c(
                              "span",
                              {
                                staticClass: "textSecondary--text",
                                class: _vm.noCapitalize
                                  ? ""
                                  : "text-capitalize",
                              },
                              [_vm._v("- " + _vm._s(data.item.subtext))]
                            )
                          : _vm._e(),
                      ]
                    ),
                  ]
                )
              : _vm._e(),
            _vm.isCustom && !_vm.loading
              ? _c("div", { staticClass: "d-flex align-center full-width" }, [
                  data.item.hasNoEth
                    ? _c(
                        "div",
                        { staticClass: "no-pointer-events titlePrimary--text" },
                        [
                          _vm._v(" " + _vm._s(data.item.text) + " "),
                          _c(
                            "a",
                            {
                              staticClass: "all-pointer-events",
                              attrs: { target: "_blank", href: data.item.link },
                            },
                            [_vm._v(_vm._s(data.item.linkText))]
                          ),
                        ]
                      )
                    : _vm._e(),
                  data.item.name
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "d-flex align-center justify-space-between full-width",
                        },
                        [
                          !_vm.loading
                            ? _c(
                                "div",
                                { staticClass: "d-flex align-center" },
                                [
                                  !_vm.normalDropdown
                                    ? _c("mew-token-container", {
                                        staticClass: "mr-1",
                                        attrs: {
                                          loading: _vm.loading,
                                          img: !data.item.img
                                            ? null
                                            : data.item.img,
                                          name: data.item.name,
                                          size: "small",
                                        },
                                      })
                                    : _vm._e(),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "ml-2 my-2 d-flex flex-column",
                                      class: _vm.noCapitalize
                                        ? ""
                                        : "text-capitalize",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          data.item.symbol ||
                                            data.item.name ||
                                            data.item
                                        ) + " "
                                      ),
                                      data.item.tokenBalance ||
                                      data.item.subtext
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "mew-caption font-weight-regular textSecondary--text",
                                              class: _vm.noCapitalize
                                                ? ""
                                                : "text-capitalize",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  data.item.tokenBalance
                                                    ? data.item.tokenBalance +
                                                        " " +
                                                        data.item.symbol
                                                    : data.item.subtext
                                                )
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex justify-center flex-column align-end",
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    data.item.totalBalance || data.item.price
                                  )
                                ),
                              ]),
                              data.item.totalBalance
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "mew-caption font-weight-regular textSecondary--text",
                                    },
                                    [_vm._v("@ " + _vm._s(data.item.price))]
                                  )
                                : _vm._e(),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                ])
              : _vm._e(),
          ]
        },
      },
    ]),
    model: {
      value: _vm.selectModel,
      callback: function ($$v) {
        _vm.selectModel = $$v
      },
      expression: "selectModel",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }