var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mobile-menu", class: { visible: _vm.isOpen } },
    [
      _c("div", { staticClass: "mobile-menu__wrap" }, [
        _c(
          "a",
          {
            staticClass: "mobile-menu__close",
            attrs: { href: "javascript:void(0)" },
            on: { click: _vm.closeMobileMenu },
          },
          [_c("close-icon")],
          1
        ),
        _c(
          "a",
          {
            staticClass: "mobile-menu__access-button",
            style: _vm.showAccess,
            attrs: {
              href: "https://www.myetherwallet.com/wallet/access",
              target: "_blank",
            },
          },
          [_vm._v("Access my wallet")]
        ),
        _c(
          "div",
          { staticClass: "matomo-tracking-switch d-md-none pt-5" },
          [
            _c("v-switch", {
              attrs: {
                "input-value": _vm.consentToTrack,
                inset: "",
                label: "Data Tracking " + (_vm.consentToTrack ? "On" : "Off"),
                color: "greenPrimary",
                "off-icon": "mdi-alert-circle",
              },
              on: { change: _vm.setConsent },
            }),
            _c("p", { staticClass: "version-style" }, [
              _c(
                "a",
                {
                  attrs: {
                    href:
                      "https://github.com/MyEtherWallet/MyEtherWallet/releases/tag/v" +
                      _vm.packageVersion,
                  },
                },
                [_vm._v("Version: v" + _vm._s(_vm.packageVersion))]
              ),
            ]),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }