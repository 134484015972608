import { AAT } from '../types';
export default {
  type: AAT,
  service: 'arthera-test-ws',
  url: 'wss://ws-test.arthera.net/',
  port: 443,
  auth: false,
  username: '',
  password: ''
};
