var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-btn",
    {
      class: [_vm.buttonClasses, "mew-icon-button"],
      attrs: {
        target: _vm.btnLink ? "_blank" : "",
        href: _vm.btnLink,
        color: _vm.buttonColor,
        disabled: _vm.disabled,
        depressed: "",
        outlined: _vm.hasOutline,
        text: _vm.isTransparent,
        width: _vm.size,
        height: _vm.size,
        "min-width": _vm.size,
        "min-height": _vm.size,
        dark: !_vm.disabled,
        fab: _vm.rounded,
      },
    },
    [
      _vm.mdiIcon && !_vm.loading
        ? _c(
            "v-icon",
            {
              attrs: {
                color: "buttonGrayDark",
                "x-small": _vm.mdiIconSize === _vm.mdiIconSizes.xsmall,
                small: _vm.mdiIconSize === _vm.mdiIconSizes.small,
                dense: _vm.mdiIconSize === _vm.mdiIconSizes.dense,
                large: _vm.mdiIconSize === _vm.mdiIconSizes.large,
                "x-large": _vm.mdiIconSize === _vm.mdiIconSizes.xlarge,
              },
            },
            [_vm._v(" mdi-" + _vm._s(_vm.mdiIcon) + " ")]
          )
        : _vm._e(),
      _vm.loading
        ? _c("v-progress-circular", {
            attrs: { indeterminate: "", size: "15", color: _vm.loadingColor },
          })
        : _vm._e(),
      !_vm.loading
        ? _c(
            "div",
            { staticClass: "d-flex justify-center align-center" },
            [_vm._t("default")],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }