var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        "d-flex hash-container mew-address",
        _vm.justifyStart ? "justify-start" : "justify-end",
      ],
    },
    [
      _c("span", { staticClass: "firstPart" }, [_vm._v(_vm._s(_vm.start))]),
      _c("span", { staticClass: "lastPart" }, [_vm._v(_vm._s(_vm.end))]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }