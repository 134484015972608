var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c("mew-module", {
        staticClass: "bgWalletBlock",
        attrs: {
          "has-elevation": "",
          subtitle: "My Staking Summary",
          "has-body-padding": false,
        },
        scopedSlots: _vm._u([
          {
            key: "moduleBody",
            fn: function () {
              return [
                _c("div", { staticClass: "pt-4" }, [
                  _c(
                    "div",
                    { staticClass: "staking-summary-table-header py-1" },
                    [
                      _c(
                        "v-row",
                        {
                          staticClass: "px-2 px-sm-8",
                          attrs: { "no-gutters": "" },
                        },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "staking-summary-table-header-item",
                              attrs: { cols: "2", offset: "2" },
                            },
                            [_vm._v(" Rewards ")]
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "staking-summary-table-header-item",
                              attrs: {
                                cols: "4",
                                offset: "4",
                                "offset-sm": "2",
                              },
                            },
                            [_vm._v(" Staking Summary ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "staking-summary-table-body" },
                    [
                      _vm.showStaked
                        ? _c(
                            "v-row",
                            {
                              staticClass: "px-2 px-sm-8 py-4",
                              attrs: { "no-gutters": "" },
                            },
                            [
                              _c("v-col", { attrs: { cols: "1" } }, [
                                _c("img", {
                                  attrs: {
                                    src: require("@/assets/images/icons/dapps/icon-dapp-stake.svg"),
                                    alt: "Staked Logo",
                                    width: "40px",
                                  },
                                }),
                              ]),
                              _c(
                                "v-col",
                                { attrs: { cols: "5", sm: "4", offset: "1" } },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "d-flex align-center mb-1" },
                                    [
                                      _c("img", {
                                        staticClass: "circular-image",
                                        attrs: {
                                          src: _vm.network.type.icon,
                                          alt: "ETH Logo",
                                          width: "27px",
                                        },
                                      }),
                                      _c(
                                        "span",
                                        {
                                          class: [
                                            _vm.$vuetify.smAndUp
                                              ? "mew-heading-2"
                                              : "mew-heading-3",
                                            "ml-2",
                                          ],
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm._f("concatStr")(
                                                  _vm.stakedRewards.totalRewards
                                                )
                                              ) +
                                              " ETH "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "mew-heading-4 font-weight-bold greyPrimary--text",
                                      staticStyle: { "padding-left": "35px" },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.stakedRewardsFiat) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "mew-body font-weight-bold greenPrimary--text",
                                      staticStyle: { "padding-left": "35px" },
                                    },
                                    [_vm._v(" " + _vm._s(_vm.stakedApy) + " ")]
                                  ),
                                ]
                              ),
                              _c(
                                "v-col",
                                {
                                  attrs: {
                                    cols: "5",
                                    sm: "3",
                                    "offset-sm": "0",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "d-flex align-center mb-1" },
                                    [
                                      _c("img", {
                                        staticClass: "circular-image",
                                        attrs: {
                                          src: _vm.network.type.icon,
                                          alt: "ETH Logo",
                                          width: "27px",
                                        },
                                      }),
                                      _c(
                                        "span",
                                        {
                                          class: [
                                            _vm.$vuetify.smAndUp
                                              ? "mew-heading-2"
                                              : "mew-heading-3",
                                            "ml-2",
                                          ],
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm._f("concatStr")(
                                                  _vm.stakedRewards.totalStaked
                                                )
                                              ) +
                                              " ETH "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "mew-heading-4 font-weight-bold greyPrimary--text",
                                      staticStyle: { "padding-left": "35px" },
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.stakedAmountFiat) + " "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "d-flex align-center justify-center justify-sm-end pt-3 pt-sm-0",
                                  attrs: {
                                    cols: "12",
                                    sm: "2",
                                    offset: "0",
                                    "offset-sm": "1",
                                  },
                                },
                                [
                                  _c("mew-button", {
                                    attrs: {
                                      title: "Stake more",
                                      "btn-style": "outline",
                                      "btn-size": "large",
                                    },
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.navigateToStaked.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.showCbStake
                        ? _c(
                            "v-row",
                            {
                              class: [
                                "px-2 px-sm-8 py-4",
                                _vm.showStaked ? "cb-item-border" : "",
                              ],
                              attrs: { "no-gutters": "" },
                            },
                            [
                              _c("v-col", { attrs: { cols: "1" } }, [
                                _c("img", {
                                  attrs: {
                                    src: require("@/assets/images/icons/dapps/icon-dapp-coinbase.svg"),
                                    alt: "Staked Logo",
                                    width: "40px",
                                  },
                                }),
                              ]),
                              _c(
                                "v-col",
                                { attrs: { cols: "5", sm: "4", offset: "1" } },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "d-flex align-center mb-1" },
                                    [
                                      _c("img", {
                                        staticClass: "circular-image",
                                        attrs: {
                                          src: _vm.network.type.icon,
                                          alt: "ETH Logo",
                                          width: "27px",
                                        },
                                      }),
                                      _c(
                                        "span",
                                        {
                                          class: [
                                            _vm.$vuetify.smAndUp
                                              ? "mew-heading-2"
                                              : "mew-heading-3",
                                            "ml-2",
                                          ],
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm._f("concatStr")(
                                                  _vm.cbStakeRewards
                                                    .totalRewards
                                                )
                                              ) +
                                              " ETH "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "mew-heading-4 font-weight-bold greyPrimary--text",
                                      staticStyle: { "padding-left": "35px" },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.cbStakeRewardsFiat) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "mew-body font-weight-bold greenPrimary--text",
                                      staticStyle: { "padding-left": "35px" },
                                    },
                                    [_vm._v(" APY 4.00% ")]
                                  ),
                                ]
                              ),
                              _c(
                                "v-col",
                                {
                                  attrs: {
                                    cols: "5",
                                    sm: "3",
                                    "offset-sm": "0",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "d-flex align-center mb-1" },
                                    [
                                      _c("img", {
                                        staticClass: "circular-image",
                                        attrs: {
                                          src: _vm.network.type.icon,
                                          alt: "ETH Logo",
                                          width: "27px",
                                        },
                                      }),
                                      _c(
                                        "span",
                                        {
                                          class: [
                                            _vm.$vuetify.smAndUp
                                              ? "mew-heading-2"
                                              : "mew-heading-3",
                                            "ml-2",
                                          ],
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm._f("concatStr")(
                                                  _vm.cbStakeRewards.totalStaked
                                                )
                                              ) +
                                              " ETH "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "mew-heading-4 font-weight-bold greyPrimary--text",
                                      staticStyle: { "padding-left": "35px" },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.cbStakeAmountFiat) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "d-flex align-center justify-center justify-sm-end pt-3 pt-sm-0",
                                  attrs: {
                                    cols: "12",
                                    sm: "2",
                                    offset: "0",
                                    "offset-sm": "1",
                                  },
                                },
                                [
                                  _c("mew-button", {
                                    attrs: {
                                      title: "Stake more",
                                      "btn-style": "outline",
                                      "btn-size": "large",
                                    },
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.navigateToCbStake.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }