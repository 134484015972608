import { ARB } from '../types';
export default {
  type: ARB,
  service: 'myetherwallet.com-ws',
  url: 'wss://nodes.mewapi.io/ws/arb',
  port: 443,
  auth: false,
  username: '',
  password: ''
};
