var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-flex align-center" },
    [
      _c(
        "span",
        {
          staticClass: "mew-body textPrimary--text mr-4 cursor-pointer",
          on: { click: _vm.switchToggle },
        },
        [_vm._v(_vm._s(_vm.label))]
      ),
      _c("v-switch", {
        staticClass: "mew-switch",
        attrs: { ripple: false, color: "white", inset: "" },
        model: {
          value: _vm.value,
          callback: function ($$v) {
            _vm.value = $$v
          },
          expression: "value",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }