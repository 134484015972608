import { AA } from '../types';
export default {
  type: AA,
  service: 'arthera-ws',
  url: 'wss://ws.arthera.net',
  port: 443,
  auth: false,
  username: '',
  password: ''
};
