import TXDC from '../types/tXDC';
export default {
  type: TXDC,
  service: 'xdc-network-ws',
  url: 'wss://ws.apothem.network/',
  port: 443,
  auth: false,
  username: '',
  password: ''
};
