var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass: "info-card elevation-3",
      attrs: { "max-width": _vm.maxWidth },
    },
    [
      _c(
        "div",
        { staticClass: "pa-5" },
        [
          _c("div", { staticClass: "text-center" }, [
            _c("img", { attrs: { src: _vm.icon } }),
          ]),
          _c(
            "v-card-title",
            {
              class: [
                "font-weight-bold",
                "mew-heading-2",
                "titlePrimary--text",
                "pt-0",
              ],
            },
            [
              _vm._v(" " + _vm._s(_vm.title) + " "),
              _vm._t("titleIconContainer"),
            ],
            2
          ),
          _c(
            "v-card-subtitle",
            { staticClass: "titlePrimary--text mt-1" },
            [_vm._t("desc"), _vm._v(" " + _vm._s(_vm.desc) + " ")],
            2
          ),
          _vm.link.url && _vm.link.title
            ? _c(
                "v-card-text",
                [
                  _c(
                    "a",
                    {
                      staticClass: "font-weight-medium",
                      attrs: { href: _vm.link.url },
                    },
                    [
                      _vm._v(_vm._s(_vm.link.title)),
                      _c(
                        "v-icon",
                        {
                          staticClass: "subtitle-1 ml-1",
                          attrs: { color: "primary" },
                        },
                        [_vm._v("mdi-arrow-right")]
                      ),
                    ],
                    1
                  ),
                  _vm._t("buttonContainer"),
                ],
                2
              )
            : _vm._e(),
        ],
        1
      ),
      _c("div", { staticClass: "text-center" }, [_vm._t("cardImg")], 2),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }