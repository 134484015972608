var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { staticClass: "mx-0", attrs: { align: "end", justify: "center" } },
    [
      _c(
        "v-sheet",
        {
          staticClass: "px-10 py-6 rounded",
          attrs: { color: "warning", height: "100%", width: "100%" },
        },
        [
          _c(
            "v-row",
            { attrs: { align: "start", justify: "start" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  _c(
                    "v-icon",
                    {
                      staticClass: "title",
                      attrs: { color: "warning darken-2" },
                    },
                    [_vm._v(" mdi-alert ")]
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "d-flex flex-column titlePrimary--text" },
                [
                  _c(
                    "span",
                    { staticClass: "text-uppercase font-weight-bold" },
                    [_vm._v(_vm._s(_vm.title))]
                  ),
                  _c("span", [
                    _vm._v(" " + _vm._s(_vm.description) + " "),
                    !_vm.bottom
                      ? _c("a", { attrs: { href: _vm.linkObj.url } }, [
                          _vm._v(_vm._s(_vm.linkObj.title) + " "),
                        ])
                      : _vm._e(),
                  ]),
                  _vm.bottom
                    ? _c("span", [
                        _c("a", { attrs: { href: _vm.linkObj.url } }, [
                          _vm._v(_vm._s(_vm.linkObj.title) + " "),
                        ]),
                      ])
                    : _vm._e(),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }