var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "full-width" },
    [
      _vm.compact
        ? _c("div", { staticClass: "d-flex justify-center" }, [
            _c(
              "div",
              { staticClass: "d-inline-block" },
              _vm._l(_vm.items, function (item, i) {
                return _c(
                  "div",
                  {
                    key: i,
                    staticClass: "stepper-item d-flex align-center",
                    class: _vm.stepNumber == i + 1 ? "stepper-item-active" : "",
                  },
                  [
                    _c("div", { staticClass: "stepper-number mr-2" }, [
                      _vm._v(" " + _vm._s(i + 1) + " "),
                    ]),
                    _c("div", { staticClass: "stepper-title" }, [
                      _vm._v(" " + _vm._s(item.name) + " "),
                    ]),
                  ]
                )
              }),
              0
            ),
          ])
        : _vm._e(),
      !_vm.compact
        ? _c(
            "v-stepper",
            {
              staticClass: "mew-stepper",
              attrs: { "alt-labels": "", color: "primary" },
              model: {
                value: _vm.stepNumber,
                callback: function ($$v) {
                  _vm.stepNumber = $$v
                },
                expression: "stepNumber",
              },
            },
            [
              _c(
                "v-stepper-header",
                [
                  _vm._l(_vm.items, function (item, i) {
                    return [
                      _c(
                        "v-stepper-step",
                        {
                          key: i + item,
                          attrs: {
                            color: "expandHeader",
                            step: i + 1,
                            "complete-icon": "mdi-check",
                            complete: _vm.stepNumber > i + 1,
                          },
                        },
                        [_vm._v(" " + _vm._s(item.name) + " ")]
                      ),
                      i < _vm.items.length - 1
                        ? _c("v-divider", { key: i })
                        : _vm._e(),
                    ]
                  }),
                ],
                2
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._l(_vm.items, function (step, i) {
        return _c(
          "div",
          { key: step + i },
          [_vm._t("stepperContent" + (i + 1))],
          2
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }