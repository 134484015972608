var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "home--enkrypt", class: _vm.bgStyle },
    [
      _c(
        "v-container",
        { staticClass: "product-info-container" },
        [
          _c(
            "v-row",
            { staticClass: "justify-space-between" },
            [
              _c(
                "v-col",
                {
                  staticClass: "product-contexts position-relative",
                  style: _vm.isMobile
                    ? "padding-top: 70px; padding-bottom: 50px"
                    : "padding-top: 100px; padding-bottom: 100px",
                  attrs: { cols: "12", md: "5" },
                },
                [
                  _c("img", {
                    staticClass: "mb-4",
                    attrs: {
                      src: require("@/assets/images/icons/enkrypt/icon-enkrypt-colored.svg"),
                      alt: "Enkrypt",
                      height: "28",
                    },
                  }),
                  _c("div", { staticClass: "text-title mb-5" }, [
                    _vm._v(" Introducing XOW’s multichain wallet extension "),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "text-subtitle mb-md-10",
                      staticStyle: { color: "#60666d" },
                    },
                    [
                      _vm._v(
                        " Easily access all your favorite apps across Ethereum and Polkadot chains, buy crypto, swap tokens, and manage your NFTs. Welcome to the multichain future. "
                      ),
                    ]
                  ),
                  _c("div", { staticClass: "text-center text-md-left" }, [
                    _c("img", {
                      staticClass: "cx-previews-mobile d-inline d-md-none",
                      attrs: {
                        src: require("@/assets/images/snippets/snippet-cx-previews.png"),
                        alt: "Enkrypt",
                      },
                    }),
                    _c(
                      "div",
                      [
                        _c(
                          "mew-button",
                          {
                            staticClass: "extension-btn",
                            staticStyle: {
                              "border-radius": "100px !important",
                            },
                            attrs: {
                              "color-theme": "#7E44F2",
                              "btn-size": "large",
                              "btn-link": "https://www.enkrypt.com",
                            },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.trackEnkrypt.apply(null, arguments)
                              },
                            },
                          },
                          [
                            _c("img", {
                              staticClass: "mr-2",
                              attrs: {
                                src: _vm.browserLogo,
                                alt: _vm.browser,
                                height: "25",
                              },
                            }),
                            _c("span", { staticClass: "font-weight-bold" }, [
                              _vm._v(
                                " Install for " + _vm._s(_vm.browser) + " "
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "a",
                          {
                            staticClass:
                              "text-decoration-underline textSecondary--text ml-5",
                            attrs: {
                              href: "https://www.enkrypt.com",
                              target: "_blank",
                            },
                            on: { click: _vm.trackEnkryptLearnMore },
                          },
                          [_vm._v("Learn more")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
              _c(
                "v-col",
                {
                  staticClass: "d-none d-md-block position-relative",
                  attrs: { cols: "12", md: "7" },
                },
                [
                  _c("img", {
                    style: _vm.isMobile
                      ? "width: 100%; margin-top: 0px"
                      : "width: 100%; margin-top: 33px",
                    attrs: {
                      src: require("@/assets/images/snippets/snippet-cx-previews.png"),
                      alt: "Enkrypt",
                    },
                  }),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }