var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-alert",
    {
      staticClass: "mew-alert mb-0 bs-small",
      class: [
        _vm.alertClasses,
        _vm.hideAlertIcon ? "pl-7" : "pl-4",
        "py-3 pr-5",
      ],
      attrs: {
        "colored-border": "",
        color: _vm.alertColor,
        border: "left",
        icon: _vm.alertIcon,
        dismissible: "",
      },
      scopedSlots: _vm._u([
        {
          key: "close",
          fn: function (ref) {
            var toggle = ref.toggle
            return [
              !_vm.hideCloseIcon
                ? _c(
                    "v-btn",
                    {
                      staticClass: "close-btn",
                      attrs: { icon: "" },
                      on: { click: toggle },
                    },
                    [
                      _c(
                        "v-icon",
                        {
                          staticClass: "pa-1",
                          attrs: { color: _vm.alertColor, size: "16" },
                        },
                        [_vm._v(" mdi-close ")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          },
        },
      ]),
    },
    [
      _c(
        "div",
        { class: [_vm.hideAlertIcon ? "" : "pl-1"] },
        [
          _vm.title
            ? _c(
                "span",
                { staticClass: "mew-body font-weight-bold textDark--text" },
                [_vm._v(_vm._s(_vm.title))]
              )
            : _vm._e(),
          _vm.description
            ? _c("div", { staticClass: "mew-body textDark--text" }, [
                _vm._v(" " + _vm._s(_vm.description) + " "),
                _vm.linkObject
                  ? _c(
                      "a",
                      {
                        staticClass: "textDark--text text-decoration-underline",
                        attrs: { target: "_blank", href: _vm.linkObject.url },
                      },
                      [_vm._v(_vm._s(_vm.linkObject.text))]
                    )
                  : _vm._e(),
              ])
            : _vm._e(),
          _vm._t("default"),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }