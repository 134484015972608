var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "core-components--app-table",
      class: _vm.containerClass,
      style: _vm.containerStyle,
    },
    [
      _vm.title
        ? _c("div", { staticClass: "font-weight-bold mt-6 ml-5 mb-10" }, [
            _vm._v(" " + _vm._s(_vm.title) + " "),
          ])
        : _vm._e(),
      _vm._t("default"),
      _vm.loading
        ? _c(
            "div",
            { staticClass: "skeleton-loader-container" },
            _vm._l(Number(_vm.loaderCount), function (n) {
              return _c(
                "div",
                { key: n },
                [
                  _c("v-skeleton-loader", {
                    attrs: { width: "100%", type: "heading" },
                  }),
                ],
                1
              )
            }),
            0
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }