var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "dapps--eth-blocks--module-eth-block-batch-minting py-13 px-5 px-sm-15 px-lg-15",
    },
    [
      _c(
        "v-row",
        {
          attrs: {
            "justify-lg": "space-between",
            "justify-md": "space-between",
          },
        },
        [
          _c("v-col", { attrs: { cols: "12", md: "6", lg: "7" } }, [
            _c("div", { staticClass: "d-flex align-center justify-start" }, [
              _c("div", [
                _c("div", { staticClass: "pb-3 mew-heading-2" }, [
                  _vm._v("My Batch"),
                ]),
                _vm.blockCount >= 1
                  ? _c("div", { staticClass: "mew-body textMedium--text" }, [
                      _vm._v(
                        " You have added " +
                          _vm._s(_vm.blockCount) +
                          " ETH " +
                          _vm._s(_vm.pluralizeBlockCount) +
                          ' for Minting. Please review and click "Proceed to Minting." '
                      ),
                    ])
                  : _vm._e(),
              ]),
            ]),
            _vm.isLoading && !_vm.blocks.length
              ? _c(
                  "div",
                  _vm._l(3, function (block, idx) {
                    return _c("block-result-component", {
                      key: "loadingBlockBatchResult" + block,
                      attrs: {
                        "is-loading": "",
                        "show-add": false,
                        "has-border": idx != 2,
                      },
                    })
                  }),
                  1
                )
              : _vm.blocks.length === 0
              ? _c(
                  "div",
                  { staticClass: "mt-3" },
                  [
                    _c("mew-alert", {
                      attrs: {
                        title: "No Eth Blocks added to batch minting!",
                        description:
                          'To mint blocks in batches, find a block and click on "Add to Batch"',
                        theme: "info",
                        "hide-close-icon": "",
                      },
                    }),
                  ],
                  1
                )
              : _c(
                  "div",
                  _vm._l(_vm.blocks, function (block, idx) {
                    return _c("block-result-component", {
                      key: block.blockNumber,
                      attrs: {
                        "block-handler": block,
                        "is-loading": false,
                        "show-add": false,
                        "remove-me": _vm.removeMe,
                        "has-border": idx != _vm.blocks.length - 1,
                      },
                    })
                  }),
                  1
                ),
          ]),
          _c("v-col", { attrs: { cols: "12", md: "5", lg: "4" } }, [
            _c(
              "div",
              { staticClass: "minting-info-container pa-5 border-radius--5px" },
              [
                _c(
                  "div",
                  { staticClass: "d-flex justify-space-between pb-4" },
                  [
                    _c("div", { staticClass: "mew-body" }, [
                      _vm._v(_vm._s(_vm.totalAvailable) + " Blocks"),
                    ]),
                    _c("div", [
                      _c("div", { staticClass: "mew-body" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.totalMintPrice) +
                            " " +
                            _vm._s(_vm.network.type.currencyName) +
                            " "
                        ),
                      ]),
                      _c(
                        "div",
                        { staticClass: "mew-label textLight--text text-end" },
                        [_vm._v(" " + _vm._s(_vm.totalMintPriceFiat) + " ")]
                      ),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "d-flex justify-space-between pb-4" },
                  [
                    _c("div", [
                      _c("div", { staticClass: "mew-body" }, [
                        _vm._v("Transaction Fee"),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass:
                            "mew-body greenPrimary--text cursor--pointer",
                          on: { click: _vm.openSettings },
                        },
                        [_vm._v(" Edit Priority ")]
                      ),
                    ]),
                    _c("div", [
                      _c("div", { staticClass: "mew-body" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.totalNetworkFee) +
                            " " +
                            _vm._s(_vm.network.type.currencyName) +
                            " "
                        ),
                      ]),
                      _c(
                        "div",
                        { staticClass: "mew-label textLight--text text-end" },
                        [_vm._v(" " + _vm._s(_vm.totalNetworkFiatFee) + " ")]
                      ),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "d-flex justify-space-between pb-4" },
                  [
                    _c("div", { staticClass: "mew-heading-3" }, [
                      _vm._v("Total"),
                    ]),
                    _c("div", [
                      _c("div", { staticClass: "mew-heading-3" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.totalTransactionPrice) +
                            " " +
                            _vm._s(_vm.network.type.currencyName) +
                            " "
                        ),
                      ]),
                      _c(
                        "div",
                        { staticClass: "mew-body textLight--text text-end" },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.totalTransactionFiatPrice) + " "
                          ),
                        ]
                      ),
                    ]),
                  ]
                ),
                _c("mew-button", {
                  attrs: {
                    title: _vm.hasEnoughEth
                      ? "Proceed to minting"
                      : "Not enough ETH",
                    "has-full-width": "",
                    disabled:
                      !_vm.hasEnoughEth || _vm.isLoading || _vm.isCartEmpty,
                    loading: _vm.isLoading,
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.mintBlocks.apply(null, arguments)
                    },
                  },
                }),
                !_vm.hasEnoughEth
                  ? _c(
                      "div",
                      { staticClass: "d-flex align-center justify-end mt-4" },
                      [
                        _c(
                          "div",
                          { staticClass: "error--text mew-label mr-2" },
                          [_vm._v(_vm._s(_vm.notEnoughMessage))]
                        ),
                        _c(
                          "a",
                          {
                            staticClass: "mew-label font-weight-medium",
                            on: {
                              click: function () {
                                _vm.openBuySell("ETHBlocksBatchMint")
                              },
                            },
                          },
                          [
                            _vm._v(
                              " Buy more " +
                                _vm._s(_vm.network.type.name) +
                                ". "
                            ),
                          ]
                        ),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }