var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "new-look-banner d-flex align-center" }, [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "row no-gutters" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("p", [
              _vm._v(
                " XOW got a new look! It's the same secure wallet. More info: "
              ),
              _c(
                "a",
                {
                  attrs: {
                    href: "https://x.com/myetherwallet",
                    target: "_blank",
                  },
                },
                [_vm._v("@MyEtherWallet")]
              ),
            ]),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }