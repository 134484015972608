var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    {
      class: [
        "mew-banner full-width font-weight-medium pa-6",
        !_vm.textObj.exit ? "d-flex align-center" : "",
      ],
      style: { backgroundImage: "url(" + _vm.banner + ")" },
      attrs: { fluid: "" },
    },
    [
      _vm.textObj.exit
        ? _c(
            "v-row",
            {
              staticClass: "cursor-pointer error--text exit-container mr-3",
              on: { click: _vm.closeBanner },
            },
            [
              _c(
                "v-col",
                {
                  staticClass: "d-flex justify-end align-center pa-0",
                  attrs: { "offset-md": "6" },
                },
                [
                  _c(
                    "v-icon",
                    { staticClass: "mr-2", attrs: { color: "error" } },
                    [_vm._v(" mdi-close-circle-outline ")]
                  ),
                  _c("span", [_vm._v(_vm._s(_vm.textObj.exit))]),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-row",
        {
          staticClass:
            "d-flex text-center align-center justify-center white--text flex-column pa-4",
        },
        [
          _c("v-col", { staticClass: "pa-0" }, [
            _c("span", { staticClass: "mew-subtitle" }, [
              _vm._v(_vm._s(_vm.textObj.title)),
            ]),
          ]),
          _c("v-col", { staticClass: "pa-0" }, [
            _c("span", { staticClass: "mew-body" }, [
              _vm._v(_vm._s(_vm.textObj.subtext) + " "),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }