var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "tippy-component",
    {
      staticClass: "mew-tooltip",
      staticStyle: { "line-height": "initial" },
      attrs: {
        arrow: "",
        theme: "light",
        "max-width": _vm.maxWidth,
        content: _vm.text,
      },
      scopedSlots: _vm._u(
        [
          {
            key: "trigger",
            fn: function () {
              return [
                !_vm.hideIcon
                  ? _c(
                      "v-icon",
                      {
                        staticClass: "cursor-pointer",
                        attrs: { color: "searchText", small: "" },
                      },
                      [_vm._v(" mdi-information ")]
                    )
                  : _vm._e(),
                _vm._t("activatorSlot"),
              ]
            },
            proxy: true,
          },
        ],
        null,
        true
      ),
    },
    [_vm._t("contentSlot")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }