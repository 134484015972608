var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.address,
            expression: "address",
          },
        ],
        staticStyle: { position: "relative" },
        style: "width: " + _vm.width + "; height: " + _vm.height,
      },
      [
        _c("img", {
          ref: "blockie",
          staticStyle: { display: "block", "border-radius": "50%" },
          attrs: { src: _vm.blockieImg, alt: "Blockie Image" },
        }),
        !_vm.flat ? _c("div", { staticClass: "inset-shadow" }) : _vm._e(),
      ]
    ),
    _vm.currency
      ? _c("img", {
          staticClass: "currency-icon",
          attrs: { alt: "icon", src: _vm.currency },
        })
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }