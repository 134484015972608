var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-navigation-drawer",
        {
          staticClass: "wallet-sidemenu",
          attrs: {
            app: "",
            width: "300",
            dark: _vm.$vuetify.theme.dark,
            color: "bgSideMenu",
          },
          scopedSlots: _vm._u([
            {
              key: "prepend",
              fn: function () {
                return [
                  _c(
                    "mew-popup",
                    {
                      attrs: {
                        footer: _vm.footer,
                        show: _vm.isOpenNetworkOverlay || !_vm.validNetwork,
                        title: _vm.validNetwork
                          ? "Select Network"
                          : "Current network is not supported. Select a network below.",
                        "content-size": "large",
                        close: _vm.validNetwork
                          ? _vm.closeNetworkOverlay
                          : null,
                        "has-body-content": "",
                        "has-buttons": false,
                        "left-btn": _vm.leftBtn,
                        "hide-close-btn": "",
                        "large-title": _vm.validNetwork,
                      },
                    },
                    [
                      _c("network-switch", {
                        attrs: { "filter-types": _vm.filterNetworks },
                        on: { newNetwork: _vm.closeNetworkOverlay },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "pa-5 pb-3" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "mt-2 mb-4 d-flex align-center justify-space-between",
                        },
                        [
                          _c(
                            "router-link",
                            { attrs: { to: _vm.offlineModeRoute } },
                            [
                              _c("img", {
                                attrs: {
                                  width: "120",
                                  src: require("@/assets/images/icons/logo-mew.svg"),
                                },
                              }),
                            ]
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "d-block d-lg-none",
                              attrs: { icon: "" },
                              on: {
                                click: function ($event) {
                                  _vm.navOpen = false
                                },
                              },
                            },
                            [
                              _c("v-icon", { attrs: { color: "white" } }, [
                                _vm._v("mdi-close"),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "d-flex justify-center" },
                            [
                              _c(
                                "v-btn-toggle",
                                {
                                  model: {
                                    value: _vm.prefix,
                                    callback: function ($$v) {
                                      _vm.prefix = $$v
                                    },
                                    expression: "prefix",
                                  },
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        value: "0x",
                                        color: "primary",
                                        outlined: "",
                                        small: "",
                                      },
                                    },
                                    [_vm._v(" 0x ")]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        value: "xdc",
                                        color: "primary",
                                        outlined: "",
                                        small: "",
                                      },
                                    },
                                    [_vm._v(" xdc ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("balance-card", {
                        attrs: { "sidemenu-status": _vm.navOpen },
                      }),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.navOpen,
            callback: function ($$v) {
              _vm.navOpen = $$v
            },
            expression: "navOpen",
          },
        },
        [
          _c(
            "v-list",
            { attrs: { dense: "" } },
            [
              _c(
                "v-list-item-group",
                [
                  _vm._l(_vm.sectionOne, function (item, idx) {
                    return [
                      _vm.shouldShow(item.route)
                        ? _c(
                            "v-list-item",
                            { key: item + idx + 1, attrs: { to: item.route } },
                            [
                              _c("v-list-item-icon", { staticClass: "mx-3" }, [
                                _c("img", {
                                  attrs: {
                                    width: "24",
                                    height: "24",
                                    src: item.icon,
                                    alt: item.title,
                                  },
                                }),
                              ]),
                              _c(
                                "v-list-item-content",
                                [
                                  _c(
                                    "v-list-item-title",
                                    {
                                      staticClass:
                                        "white--text font-weight-regular mew-body",
                                    },
                                    [_vm._v(" " + _vm._s(item.title) + " ")]
                                  ),
                                ],
                                1
                              ),
                              item.hasNew
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "new-dapp-label white--text mew-label px-1",
                                    },
                                    [_vm._v(" NEW ")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  }),
                ],
                2
              ),
            ],
            1
          ),
          _c("v-divider", { staticClass: "my-1 mx-6" }),
          !_vm.isOfflineApp
            ? _c(
                "v-list",
                { attrs: { dense: "" } },
                [
                  _c(
                    "v-list-item-group",
                    [
                      _vm._l(_vm.sectionTwo, function (item, idx) {
                        return [
                          _vm.shouldShow(item.route)
                            ? _c(
                                "v-list-item",
                                {
                                  key: item + idx,
                                  attrs: {
                                    to: item.route,
                                    "active-class": item.route
                                      ? ""
                                      : "remove-active-class",
                                  },
                                  on: {
                                    click: function ($event) {
                                      item.fn ? item.fn() : function () {}
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "v-list-item-icon",
                                    { staticClass: "mx-3" },
                                    [
                                      _c("img", {
                                        attrs: {
                                          width: "24",
                                          height: "24",
                                          src: item.icon,
                                          alt: item.title,
                                        },
                                      }),
                                    ]
                                  ),
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c(
                                        "v-list-item-title",
                                        {
                                          staticClass:
                                            "white--text mew-body font-weight-regular",
                                        },
                                        [_vm._v(" " + _vm._s(item.title) + " ")]
                                      ),
                                    ],
                                    1
                                  ),
                                  item.hasNew
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "new-dapp-label white--text mew-label px-1",
                                        },
                                        [_vm._v(" NEW ")]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      }),
                    ],
                    2
                  ),
                ],
                1
              )
            : _vm._e(),
          !_vm.isOfflineApp
            ? _c("v-divider", { staticClass: "my-1 mx-6" })
            : _vm._e(),
          !_vm.isOfflineApp
            ? _c(
                "v-list",
                { attrs: { dense: "" } },
                [
                  _c(
                    "v-list-item-group",
                    [
                      _vm._l(_vm.sectionThree, function (item, idx) {
                        return [
                          item.children
                            ? _c(
                                "v-list-group",
                                {
                                  key: item + idx + 2,
                                  attrs: {
                                    "prepend-icon": "",
                                    value: _vm.expendSubMenu(item.children),
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function () {
                                          return [
                                            _c(
                                              "v-list-item-icon",
                                              { staticClass: "mx-3" },
                                              [
                                                _c("img", {
                                                  attrs: {
                                                    width: "24",
                                                    height: "24",
                                                    src: item.icon,
                                                    alt: item.title,
                                                  },
                                                }),
                                              ]
                                            ),
                                            _c(
                                              "v-list-item-content",
                                              [
                                                _c(
                                                  "v-list-item-title",
                                                  {
                                                    staticClass:
                                                      "white--text font-weight-regular mew-body",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(item.title) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                _vm._l(item.children, function (child) {
                                  return _c(
                                    "v-list-item",
                                    {
                                      key: child.title,
                                      staticClass: "pl-4",
                                      attrs: { dense: "", to: child.route },
                                      on: {
                                        click: function ($event) {
                                          child.fn ? child.fn() : function () {}
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "v-list-item-content",
                                        [
                                          _c(
                                            "v-list-item-title",
                                            {
                                              staticClass:
                                                "pl-13 white--text font-weight-regular mew-body",
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(child.title) + " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                }),
                                1
                              )
                            : _vm._e(),
                        ]
                      }),
                    ],
                    2
                  ),
                ],
                1
              )
            : _vm._e(),
          !_vm.isOfflineApp
            ? _c("v-divider", { staticClass: "my-1 mx-6" })
            : _vm._e(),
          _c(
            "v-list",
            { attrs: { dense: "" } },
            [
              _vm._l(_vm.sectionFour, function (item, idx) {
                return _c(
                  "v-list-item",
                  {
                    key: item + idx,
                    attrs: { to: item.route },
                    on: {
                      click: function ($event) {
                        return item.fn()
                      },
                    },
                  },
                  [
                    _c("v-list-item-icon", { staticClass: "mx-3" }, [
                      _c("img", {
                        attrs: {
                          width: "24",
                          height: "24",
                          src: item.icon,
                          alt: item.title,
                        },
                      }),
                    ]),
                    _c(
                      "v-list-item-content",
                      [
                        _c(
                          "v-list-item-title",
                          {
                            staticClass:
                              "white--text mew-body font-weight-regular",
                          },
                          [_vm._v(" " + _vm._s(item.title) + " ")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
              _c(
                "div",
                { staticClass: "mt-3 px-8" },
                [
                  _c("v-switch", {
                    staticClass: "tracking-switch",
                    attrs: {
                      "hide-details": "",
                      dark: "",
                      inset: "",
                      label:
                        "Dark theme is " + (_vm.locDarkMode ? "On" : "Off"),
                      color: "white",
                      "off-icon": "mdi-alert-circle",
                    },
                    model: {
                      value: _vm.locDarkMode,
                      callback: function ($$v) {
                        _vm.locDarkMode = $$v
                      },
                      expression: "locDarkMode",
                    },
                  }),
                ],
                1
              ),
              _vm.online
                ? _c("div", { staticClass: "mt-3 px-8" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex align-center justify-space-between",
                      },
                      [
                        _c(
                          "a",
                          {
                            staticClass: "label-text",
                            attrs: {
                              href:
                                "https://github.com/MyEtherWallet/MyEtherWallet/releases/tag/v" +
                                _vm.version,
                              target: "_blank",
                            },
                          },
                          [_vm._v("v" + _vm._s(_vm.version))]
                        ),
                      ]
                    ),
                  ])
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
      _c("app-modal", {
        attrs: {
          show: _vm.openQR,
          close: _vm.closeQR,
          "has-buttons": false,
          width: "408px",
        },
        scopedSlots: _vm._u([
          {
            key: "dialogBody",
            fn: function () {
              return [_c("app-addr-qr")]
            },
            proxy: true,
          },
        ]),
      }),
      _c("mew-popup", {
        attrs: {
          "max-width": "400px",
          "hide-close-btn": "",
          show: _vm.showLogoutPopup,
          title: _vm.$t("interface.menu.logout"),
          "left-btn": {
            text: "Cancel",
            method: _vm.toggleLogout,
            color: "basic",
          },
          "right-btn": {
            text: "Log out",
            color: "error",
            method: _vm.onLogout,
            enabled: true,
          },
        },
      }),
      _c("module-settings", {
        attrs: { "on-settings": _vm.onSettings },
        on: { closeSettings: _vm.closeSettings },
      }),
      !_vm.$vuetify.breakpoint.lgAndUp
        ? _c(
            "v-system-bar",
            {
              staticClass: "d-flex d-lg-none",
              attrs: { color: "#0b1a40", app: "", height: 60 },
            },
            [
              _c(
                "v-row",
                { staticClass: "pa-3 align-center justify-space-between" },
                [
                  _c("app-btn-menu", {
                    staticClass: "mr-3",
                    nativeOn: {
                      click: function ($event) {
                        return _vm.openNavigation.apply(null, arguments)
                      },
                    },
                  }),
                  _c(
                    "router-link",
                    {
                      staticStyle: { "line-height": "0" },
                      attrs: { to: _vm.offlineModeRoute },
                    },
                    [
                      _c("img", {
                        attrs: {
                          height: "26",
                          src: require("@/assets/images/icons/logo-mew.svg"),
                        },
                      }),
                    ]
                  ),
                  _c("v-spacer"),
                  !_vm.isOfflineApp
                    ? _c("module-notifications", {
                        attrs: { "invert-icon": "" },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }