var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-sheet",
    {
      staticClass: "mew-component--white-sheet border-radius--15px",
      attrs: { color: "bgWalletBlock-base" },
    },
    [
      _c("div", { staticClass: "staking-banner" }, [
        _c("img", {
          attrs: {
            src: require("@/assets/images/backgrounds/staking-banner.png"),
            alt: "staking banner",
            width: "100%",
          },
        }),
        _c(
          "div",
          {
            staticClass:
              "staking-banner-copy py-2 py-md-10 py-sm-4 px-2 px-md-8 px-sm-4",
          },
          [
            _c("div", { staticClass: "mew-title white--text override-title" }, [
              _vm._v("Ethereum Staking"),
            ]),
            _c(
              "div",
              {
                staticClass:
                  "mew-title font-weight-regular white--text override-title",
              },
              [_vm._v(" Make your crypto make crypto ")]
            ),
            _c(
              "div",
              {
                staticClass:
                  "mew-heading-4 font-weight-regular faded pt-0 pt-md-4 d-none d-sm-block",
              },
              [
                _vm._v(
                  " Stake your ETH and get rewarded by Ethereum blockchain. "
                ),
              ]
            ),
            _c(
              "a",
              {
                staticClass: "white--text font-weight-bold",
                on: { click: _vm.trackMoreAbout },
              },
              [_vm._v("More about staking >")]
            ),
          ]
        ),
      ]),
      _c(
        "div",
        { staticClass: "pt-4 px-3 pa-md-8" },
        [
          _c("div", { staticClass: "mew-heading-1 px-4 mb-4" }, [
            _vm._v("Select staking option"),
          ]),
          _c(
            "v-row",
            {
              staticClass: "pb-12",
              attrs: {
                "align-content": "space-between",
                justify: "space-between",
                dense: "",
              },
            },
            _vm._l(_vm.dapps, function (dapp, key) {
              return _c(
                "v-col",
                {
                  key: key,
                  staticClass: "pa-1",
                  attrs: { cols: "12", lg: "6", md: "6" },
                },
                [
                  _c(
                    "v-row",
                    {
                      staticClass:
                        "pa-3 pa-sm-6 staking-item border-radius--5px elevation-2",
                      attrs: { "no-gutters": "" },
                    },
                    [
                      _c(
                        "v-col",
                        {
                          class: _vm.$vuetify.breakpoint.smAndUp
                            ? ""
                            : "align-content-center",
                          attrs: { cols: "3", sm: "2" },
                        },
                        [
                          _c("img", {
                            class: [
                              _vm.$vuetify.breakpoint.smAndUp
                                ? "border-radius--15px"
                                : "",
                            ],
                            attrs: {
                              src: dapp.icon,
                              width: "64px",
                              height: "64px",
                            },
                          }),
                        ]
                      ),
                      _c("v-col", { attrs: { cols: "9", sm: "10" } }, [
                        _c(
                          "div",
                          { staticClass: "mew-body font-weight-bold" },
                          [
                            _vm._v(" " + _vm._s(dapp.title) + " "),
                            _vm.checkIfNew(dapp.release)
                              ? _c(
                                  "v-icon",
                                  {
                                    staticClass: "ml-1 redPrimary--text",
                                    attrs: { size: "24" },
                                  },
                                  [_vm._v(" mdi-new-box ")]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "mew-body greenPrimary--text" },
                          [_vm._v(_vm._s(dapp.apr))]
                        ),
                        _c("div", { staticClass: "mew-label" }, [
                          _vm._v(" " + _vm._s(dapp.description) + " "),
                        ]),
                      ]),
                      _c(
                        "v-col",
                        {
                          staticClass: "text-center pt-3",
                          attrs: { cols: "12" },
                        },
                        [
                          _c("mew-button", {
                            attrs: {
                              title: "Start Staking",
                              "btn-size": "xsmall",
                            },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.routeTo(dapp.routeName)
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          ),
          !_vm.dapps.length
            ? _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "swap-not-available",
                      attrs: { cols: "12" },
                    },
                    [
                      _c("mew-alert", {
                        attrs: {
                          theme: "warning",
                          "hide-close-icon": "",
                          title: "Staking is not supported on this network",
                          description: "Please select a different network.",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }