var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-btn",
    {
      class: [
        _vm.getClasses(),
        "mew-super-button",
        "mew-button",
        _vm.isColumn ? "full-height" : "full-width mew-super-btn-row",
      ],
      attrs: {
        color: _vm.getColor(),
        outlined: _vm.colorTheme.toLowerCase() === _vm.colorThemes.outline,
        ripple: false,
        disabled: _vm.disabled,
        text: _vm.disabled,
        depressed: "",
      },
      on: { click: _vm.onBtnClick },
    },
    [
      _c(
        "v-row",
        {
          staticClass: "pa-3 full-width align-center",
          class: _vm.getRowClasses(),
          attrs: { justify: "space-between" },
        },
        [
          _c(
            "v-col",
            {
              class: [
                "left-container",
                "full-width",
                _vm.isColumn ? "text-center" : "text-left",
              ],
              attrs: { cols: _vm.isColumn ? 12 : _vm.leftColsNum },
            },
            [
              _c(
                "div",
                {
                  class: [
                    "title-wrapper",
                    "d-flex",
                    "align-center",
                    _vm.isColumn ? "justify-center" : "",
                  ],
                },
                [
                  _c(
                    "div",
                    { class: [_vm.fontClass, "font-weight-bold", "truncate"] },
                    [_vm._v(" " + _vm._s(_vm.title) + " ")]
                  ),
                  _vm.hasSrc(_vm.titleIcon) && !_vm.isColumn
                    ? _c(
                        "div",
                        { staticClass: "body-2" },
                        [
                          _vm.showTitleIcon(_vm.titleIcon, "img")
                            ? _c("img", {
                                staticClass: "icon title-icon",
                                attrs: { src: _vm.titleIcon, alt: "Icon" },
                              })
                            : _vm._e(),
                          _vm.showTitleIcon(_vm.titleIcon, "mew")
                            ? _c("mew-icon", {
                                staticClass: "icon title-icon",
                                attrs: {
                                  "img-height": 20,
                                  "icon-name": _vm.titleIcon,
                                },
                              })
                            : _vm._e(),
                          _vm.showTitleIcon(_vm.titleIcon, "mdi")
                            ? _c(
                                "v-icon",
                                {
                                  class: [
                                    "icon",
                                    "title-icon",
                                    _vm.titleIconClass,
                                  ],
                                },
                                [_vm._v(" " + _vm._s(_vm.titleIcon) + " ")]
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              ),
              _vm.subtitle
                ? _c(
                    "div",
                    { staticClass: "mt-2 break-word font-weight-regular" },
                    [_vm._v(" " + _vm._s(_vm.subtitle) + " ")]
                  )
                : _vm._e(),
              _vm.tag
                ? _c("div", { staticClass: "body-2 mt-1 tagLabel--text" }, [
                    _vm._v(" " + _vm._s(_vm.tag) + " "),
                  ])
                : _vm._e(),
            ]
          ),
          _c(
            "v-col",
            {
              class: [
                "right-container",
                _vm.isColumn
                  ? "text-center, pb-0"
                  : "d-flex align-center justify-center text-right",
              ],
              attrs: { cols: _vm.isColumn ? 12 : _vm.rightColsNum },
            },
            [
              _vm._t("contentSlot"),
              _vm.isNew
                ? _c(
                    "div",
                    {
                      staticClass:
                        "label-container d-flex align-center text-uppercase",
                    },
                    [
                      _c("div", { staticClass: "label text-uppercase" }, [
                        _vm._v("new"),
                      ]),
                    ]
                  )
                : _vm._e(),
              !_vm.hasSrc(_vm.rightIcon) && _vm.note && !_vm.isColumn
                ? _c(
                    "div",
                    {
                      staticClass:
                        "note truncate text-uppercase caption warning--text text--darken-1",
                    },
                    [_vm._v(" " + _vm._s(_vm.note) + " ")]
                  )
                : _vm._e(),
              _vm.showRightIcon(_vm.rightIcon, "img")
                ? _c("img", {
                    staticClass: "icon right-icon",
                    attrs: { src: _vm.rightIcon, alt: "Icon" },
                  })
                : _vm._e(),
              _vm.showRightIcon(_vm.rightIcon, "mew")
                ? _c("mew-icon", {
                    class: [
                      "icon",
                      "right-icon",
                      !_vm.isColumn ? "d-flex align-center" : "",
                    ],
                    attrs: { "img-height": 100, "icon-name": _vm.rightIcon },
                  })
                : _vm._e(),
              _vm.showRightIcon(_vm.rightIcon, "mdi")
                ? _c(
                    "v-icon",
                    { staticClass: "icon right-icon primary--text" },
                    [_vm._v(" " + _vm._s(_vm.rightIcon) + " ")]
                  )
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }