<template>
  <div>
    <!-- Display the SVG with the same width and height as before -->
    <img src="@/assets/images/icons/logo-mew.svg" alt="Logo" width="113" height="auto" />
  </div>
</template>

<script>
export default {
  name: 'SvgLogo',
}
</script>
