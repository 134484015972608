var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("the-wrapper-wallet", {
    attrs: {
      "total-left-col-items": 1,
      "has-draggable": false,
      "total-right-col-items": 1,
    },
    scopedSlots: _vm._u([
      {
        key: "leftColItem1",
        fn: function () {
          return [
            _c("the-wrapper-dapp", {
              attrs: {
                "banner-img": _vm.bannerImage,
                "banner-text": _vm.bannerText,
                "no-back-btn": "",
              },
              scopedSlots: _vm._u([
                {
                  key: "content",
                  fn: function () {
                    return [
                      _c("div", { staticClass: "mew-heading-1 px-4 mb-4" }, [
                        _vm._v("MEW DApps"),
                      ]),
                      _c(
                        "v-row",
                        _vm._l(_vm.dapps, function (dapp, key) {
                          return _c(
                            "v-col",
                            { key: key, attrs: { cols: "12", lg: "6" } },
                            [
                              _c(
                                "mew-button",
                                {
                                  staticClass: "d-flex align-start pt-7",
                                  staticStyle: { height: "173px" },
                                  attrs: {
                                    "color-theme": "buttonGrayLight",
                                    "btn-style": "light",
                                    "has-full-width": "",
                                  },
                                  nativeOn: {
                                    click: function ($event) {
                                      _vm.routeTo(_vm.dappName(dapp))
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "px-2 d-flex align-start",
                                      class:
                                        dapp.style === "outline"
                                          ? "white--text"
                                          : "textDark--text",
                                      staticStyle: { width: "100%" },
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src:
                                            dapp.rightIconType === "mew"
                                              ? require("@/assets/images/icons/dapps/icon-dapp-" +
                                                  dapp.rightIcon.toLowerCase() +
                                                  ".svg")
                                              : dapp.rightIcon,
                                          alt: dapp.title,
                                          height: "60",
                                          width: "60",
                                        },
                                      }),
                                      _c("div", { staticClass: "ml-5" }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "mb-2 d-flex align-center justify-start flex-row",
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "mew-heading-2" },
                                              [_vm._v(_vm._s(dapp.title))]
                                            ),
                                            _vm.checkIfNew(dapp.release)
                                              ? _c(
                                                  "v-icon",
                                                  {
                                                    staticClass:
                                                      "ml-1 redPrimary--text",
                                                    attrs: { size: "24" },
                                                  },
                                                  [_vm._v(" mdi-new-box ")]
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "text-left break-word font-weight-bold",
                                            staticStyle: {
                                              "line-height": "19px",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(dapp.subtitle) + " "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "text-left break-word",
                                            staticStyle: {
                                              "line-height": "19px",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(dapp.description) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "body-2 mt-1 textLight--text text-left",
                                          },
                                          [_vm._v(" " + _vm._s(dapp.tag) + " ")]
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                      !_vm.dapps.length
                        ? _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "swap-not-available",
                                  attrs: { cols: "12" },
                                },
                                [
                                  _c("mew-alert", {
                                    attrs: {
                                      theme: "warning",
                                      "hide-close-icon": "",
                                      title:
                                        "DApps are not supported on this network",
                                      description:
                                        "Please select a different network.",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "moduleBody",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        { staticClass: "swap-not-available" },
                        [_c("app-user-msg-block", { attrs: { message: "" } })],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }