var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mew-menu-popup" }, [
    _c(
      "div",
      {
        ref: "activator",
        staticClass: "mew-menu-popup-activator",
        attrs: { id: "unique-id--mew-menu-popup--activator" },
        on: {
          click: function ($event) {
            $event.stopPropagation()
            return _vm.toggleMenu.apply(null, arguments)
          },
        },
      },
      [
        _vm.btnTitle
          ? _c(
              "v-btn",
              {
                staticClass: "px-3",
                attrs: {
                  icon: _vm.icon,
                  color: _vm.color,
                  outlined: _vm.outlined,
                  "x-small": _vm.btnSize == "x-small",
                  small: _vm.btnSize == "small",
                  large: _vm.btnSize == "large",
                  "x-large": _vm.btnSize == "x-large",
                },
              },
              [
                _vm.btnIcon
                  ? _c("img", {
                      class: _vm.btnTitle ? "mr-2" : "",
                      style:
                        "height: " +
                        _vm.btnIconSize +
                        "; width: " +
                        _vm.btnIconSize,
                      attrs: { src: _vm.btnIcon, alt: "Icon" },
                    })
                  : _vm._e(),
                _c("span", { style: _vm.btnTitleStyle }, [
                  _vm._v(_vm._s(_vm.btnTitle)),
                ]),
              ]
            )
          : _vm._e(),
        _vm._t("activator"),
        _c("div", {
          staticClass: "top-arrow content-fade-base",
          class: _vm.show ? "" : "content-fade-out",
        }),
      ],
      2
    ),
    _c("div", { staticStyle: { position: "relative" } }, [
      _c(
        "div",
        {
          ref: "content",
          staticClass: "mew-menu-popup-content content-fade-base",
          class: _vm.show ? "" : "content-fade-out",
          style: _vm.contentWindowStyle,
        },
        [_vm._t("default")],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }