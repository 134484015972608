import { COTI } from '../types';
export default {
  type: COTI,
  service: 'coti.io-ws',
  url: 'wss://devnet.coti.io/ws',
  port: 443,
  auth: false,
  username: '',
  password: ''
};
