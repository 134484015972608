var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dapps-stakewise-stake pt-8 pb-13 px-3 pa-sm-15" },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            {
              class: _vm.$vuetify.breakpoint.smAndDown ? "my-10" : "pr-7",
              attrs: {
                order: _vm.$vuetify.breakpoint.smAndDown ? "last" : "",
                cols: "12",
                md: "8",
              },
            },
            [
              _c(
                "mew-sheet",
                { staticClass: "pa-15" },
                [
                  _c(
                    "div",
                    { staticClass: "mew-heading-2 textDark--text mb-8" },
                    [_vm._v("Stake ETH")]
                  ),
                  _c(
                    "div",
                    {
                      ref: "input",
                      staticClass: "d-flex align-center text-center",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "border-radius--8px bgWalletBlockDark flex-grow-1 pa-5 d-flex flex-column align-center",
                          staticStyle: { width: "30%" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "mew-caption textMedium--text font-weight-regular mb-2",
                            },
                            [_vm._v(" You give ")]
                          ),
                          _c("div", { staticClass: "stake-icon" }, [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/images/icons/icon-eth-gray.svg"),
                                alt: "Eth",
                              },
                            }),
                          ]),
                          _c("div", { staticClass: "font-weight-bold mt-2" }, [
                            _vm._v(_vm._s(_vm.currencyName)),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "px-5" },
                        [
                          _c("v-icon", { attrs: { color: "greenPrimary" } }, [
                            _vm._v("mdi-arrow-right"),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "border-radius--8px bgWalletBlockDark flex-grow-1 pa-5 d-flex flex-column align-center",
                          staticStyle: { width: "30%" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "mew-caption textMedium--text font-weight-regular mb-2",
                            },
                            [_vm._v(" You will get ")]
                          ),
                          _c("div", { staticClass: "stake-icon" }, [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/images/icons/icon-eth-gray.svg"),
                                alt: "Stakewise",
                              },
                            }),
                          ]),
                          _c("div", { staticClass: "font-weight-bold mt-2" }, [
                            _vm._v("MEWcbETH"),
                          ]),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "position--relative mt-15" },
                    [
                      _c("app-button-balance", {
                        attrs: { loading: false, balance: _vm.balanceInETH },
                      }),
                      _c("mew-input", {
                        attrs: {
                          type: "number",
                          "max-btn-obj": {
                            title: "Max",
                            method: _vm.setMax,
                            disabled: _vm.errorMessages !== "",
                          },
                          image: _vm.iconEth,
                          label: "Amount to stake",
                          placeholder: "Enter amount",
                          value: _vm.stakeAmount,
                          "error-messages": _vm.errorMessages,
                          "buy-more-str": _vm.buyMoreStr,
                        },
                        on: {
                          buyMore: function () {
                            _vm.openBuySell("CoinbaseStaking")
                          },
                          input: _vm.setAmount,
                        },
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "stake-status" }, [
                    _c("div", { staticClass: "d-flex justify-space-between" }, [
                      _c("div", [
                        _c("div", { staticClass: "mew-body" }, [
                          _vm._v(" Network Fee "),
                          _c(
                            "span",
                            {
                              staticClass:
                                "ml-2 greenPrimary--text cursor--pointer",
                              on: { click: _vm.openSettings },
                            },
                            [_vm._v(" Edit ")]
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "text-right" }, [
                        _c("div", {}, [
                          _vm._v(
                            _vm._s(_vm.ethTotalFee) +
                              " " +
                              _vm._s(_vm.currencyName)
                          ),
                        ]),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.isEthNetwork,
                                expression: "isEthNetwork",
                              },
                            ],
                            staticClass: "mew-body textLight--text",
                          },
                          [_vm._v(" " + _vm._s(_vm.gasPriceFiat) + " ")]
                        ),
                      ]),
                    ]),
                  ]),
                  _c("v-divider", { staticClass: "mt-8" }),
                  _c("div", { staticClass: "mt-6" }, [
                    _c("div", { staticClass: "font-weight-bold mb-2" }, [
                      _vm._v("How staking works"),
                    ]),
                    _c("ul", { staticClass: "textMedium--text" }, [
                      _c("li", { staticClass: "mb-2" }, [
                        _vm._v(
                          " Anyone can stake any amount of " +
                            _vm._s(_vm.currencyName) +
                            " with ETH Staking powered by Coinbase. No minimum required. "
                        ),
                      ]),
                      _c("li", { staticClass: "mb-2" }, [
                        _vm._v(
                          " When you deposit " +
                            _vm._s(_vm.currencyName) +
                            " to Coinbase via MEW web, you are issued an equivalent amount of MEWcbETH given the current conversion rate. "
                        ),
                      ]),
                      _c("li", { staticClass: "mb-2" }, [
                        _vm._v(
                          " The conversion rate changes over time, as your stake accumulates rewards and compounds automatically while you hold MEWcbETH. "
                        ),
                      ]),
                      _c("li", [
                        _vm._v(
                          " Conversion rate of MEWcbETH to " +
                            _vm._s(_vm.currencyName) +
                            " and balance of " +
                            _vm._s(_vm.currencyName) +
                            " refresh daily at 1pm UTC. "
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "mt-6" }, [
                      _c(
                        "a",
                        {
                          attrs: {
                            href: "https://help.myetherwallet.com/en/articles/8843926-stake-eth-with-coinbase-in-mew-web",
                            target: "_blank",
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "greenPrimary--text" },
                            [
                              _vm._v(" View the ETH Staking guide"),
                              _c(
                                "v-icon",
                                {
                                  staticClass: "ml-2",
                                  attrs: { color: "greenPrimary", small: "" },
                                },
                                [_vm._v(" mdi-open-in-new ")]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]),
                  ]),
                  _c("v-divider", { staticClass: "mt-9 mb-8" }),
                  _c(
                    "div",
                    { staticClass: "d-flex flex-column align-center" },
                    [
                      _c("mew-button", {
                        staticClass: "mt-8",
                        attrs: {
                          title: "Start staking",
                          "btn-size": "xlarge",
                          disabled: !_vm.isValid,
                          loading: _vm.loading,
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.stake.apply(null, arguments)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "4" } },
            [
              _c("coinbase-staking-summary", {
                staticClass: "mb-4",
                attrs: { refs: "summary" },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }