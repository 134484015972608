var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-menu",
    {
      attrs: {
        "open-on-hover": "",
        "min-width": "220",
        "content-class": "mew-menu-content",
        "offset-y": "",
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function (ref) {
            var on = ref.on
            return [
              _c(
                "div",
                _vm._g(
                  {
                    ref: "menuActivator",
                    class: [
                      _vm.activatorTextColor,
                      "cursor-pointer",
                      _vm.isMenuOpen ? "font-weight-medium" : "",
                    ],
                    attrs: { id: _vm.activatorId },
                    on: { mouseover: _vm.adjustTopArrowPosition },
                  },
                  on
                ),
                [
                  _vm._v(" " + _vm._s(_vm.listObj.name) + " "),
                  _c(
                    "v-icon",
                    {
                      staticClass: "mew-menu--direction-arrow",
                      class: [
                        "title",
                        _vm.activatorTextColor,
                        _vm.isMenuOpen ? "opened" : "",
                      ],
                    },
                    [_vm._v(" mdi-chevron-down ")]
                  ),
                ],
                1
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.isMenuOpen,
        callback: function ($$v) {
          _vm.isMenuOpen = $$v
        },
        expression: "isMenuOpen",
      },
    },
    [
      _c("div", { staticClass: "content-container" }, [
        _vm.topArrow
          ? _c("div", { staticClass: "top-arrow", style: _vm.topArrowStyle })
          : _vm._e(),
        _c(
          "div",
          { staticClass: "list-content" },
          _vm._l(_vm.listObj.items, function (item, index) {
            return _c(
              "v-list",
              { key: index },
              [
                _vm._t("mewMenuItem" + (item + 1)),
                item.title
                  ? _c(
                      "v-list-item",
                      [
                        _c(
                          "v-list-item-title",
                          {
                            staticClass: "basic--text mew-heading-3 titleItem",
                          },
                          [_vm._v(" " + _vm._s(item.title) + " ")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._l(item.items, function (subItem, idx) {
                  return _c(
                    "v-list-item",
                    {
                      key: subItem + idx,
                      staticClass: "cursor-pointer",
                      on: {
                        click: function ($event) {
                          return _vm.goTo(subItem.to)
                        },
                      },
                    },
                    [
                      _c(
                        "v-list-item-title",
                        {
                          staticClass:
                            "mew-body basic--text subItem d-flex align-center",
                        },
                        [
                          subItem.iconName
                            ? _c(
                                "v-icon",
                                {
                                  staticClass: "mr-1 basic--text",
                                  attrs: { size: "14px" },
                                },
                                [_vm._v(" " + _vm._s(subItem.iconName) + " ")]
                              )
                            : _vm._e(),
                          _vm._v(" " + _vm._s(subItem.title) + " "),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
              ],
              2
            )
          }),
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }