var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pegin-container" },
    [
      _c(
        "h2",
        { staticClass: "text-center text-uppercase font-weight-bold mt-2" },
        [_vm._v(" ↙ BTC to RBTC ")]
      ),
      _c("mew-stepper", {
        attrs: { items: _vm.stepperItems, "on-step": _vm.onStep },
        scopedSlots: _vm._u([
          {
            key: "stepperContent1",
            fn: function () {
              return [
                _vm.onStep === 1
                  ? _c("get-quote", { on: { onSubmit: _vm.onSubmit } })
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
          {
            key: "stepperContent2",
            fn: function () {
              return [
                _vm.onStep === 2
                  ? _c("accept-quote", {
                      attrs: {
                        "quote-amount": _vm.quoteAmount,
                        confirmations: _vm.confirmations,
                        "quote-hash": _vm.quoteHash,
                        "quote-url": _vm.quoteUrl,
                        "site-key": _vm.siteKey,
                      },
                      on: { onSubmit: _vm.onSubmit },
                    })
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
          {
            key: "stepperContent3",
            fn: function () {
              return [
                _vm.onStep === 3
                  ? _c("deposit-funds", {
                      attrs: {
                        "bitcoin-deposit-address-hash":
                          _vm.bitcoinDepositAddressHash,
                        signature: _vm.signature,
                        "final-amount": _vm.finalAmount,
                        "btc-address": _vm.btcAddress,
                        "minimum-age": _vm.timeForDeposit,
                        confirmations: _vm.confirmations,
                      },
                    })
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }