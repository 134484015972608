var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("the-wrapper-dapp", {
        attrs: {
          "is-new-header": true,
          "dapp-img": _vm.headerImg,
          "banner-text": _vm.header,
          "tab-items": _vm.tabs,
          "active-tab": _vm.activeTab,
          "external-contents": "",
          "on-tab": _vm.tabChanged,
          "valid-networks": _vm.validNetworks,
        },
        scopedSlots: _vm._u([
          {
            key: "tabContent1",
            fn: function () {
              return [
                _c(
                  "v-sheet",
                  {
                    staticClass: "px-3 py-2 py-md-2 mx-auto",
                    attrs: { "max-width": "700px", color: "transparent" },
                  },
                  [_c("module-pegin")],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "tabContent2",
            fn: function () {
              return [
                _c(
                  "v-sheet",
                  {
                    staticClass: "px-3 py-2 py-md-2 mx-auto",
                    attrs: { "max-width": "700px", color: "transparent" },
                  },
                  [_c("module-pegout")],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }