var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-flex align-center mew-checkbox" },
    [
      _c("v-checkbox", {
        staticClass: "titlePrimary--text",
        attrs: {
          "hide-details": "",
          dense: _vm.dense,
          "off-icon": "mdi-circle-outline",
          "on-icon": "mdi-check-circle",
        },
        model: {
          value: _vm.isChecked,
          callback: function ($$v) {
            _vm.isChecked = $$v
          },
          expression: "isChecked",
        },
      }),
      _c(
        "span",
        {
          class: ["mr-2 cursor-pointer", _vm.className],
          on: { click: _vm.toggleCheckbox },
        },
        [
          _vm._v(_vm._s(_vm.label) + " "),
          _vm.link.url && _vm.link.title
            ? _c("a", { attrs: { target: "_blank", href: _vm.link.url } }, [
                _vm._v(_vm._s(_vm.link.title)),
              ])
            : _vm._e(),
          _vm._t("contentSlot"),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }