var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("the-wrapper-wallet", {
    attrs: {
      "total-left-col-items": _vm.showBanner || _vm.hasStaked ? 3 : 2,
      "has-draggable": false,
      "total-right-col-items": 2,
    },
    scopedSlots: _vm._u(
      [
        {
          key: "leftColItem1",
          fn: function () {
            return [_c("div", [_c("module-balance")], 1)]
          },
          proxy: true,
        },
        {
          key: "leftColItem2",
          fn: function () {
            return [
              _c(
                "div",
                [
                  _c("dashboard-banner", {
                    attrs: { "show-banner": _vm.showBanner },
                  }),
                  _vm.hasStaked
                    ? _c("staking-summary-card", {
                        attrs: {
                          "eth-price": _vm.ethPrice,
                          "staked-rewards": _vm.stakedRewards,
                          "cb-stake-rewards": _vm.cbStakeRewards,
                          apr: _vm.currentApr,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
        {
          key: _vm.hasBanner,
          fn: function () {
            return [_c("module-tokens")]
          },
          proxy: true,
        },
        _vm.isEthNetwork
          ? {
              key: "rightColItem1",
              fn: function () {
                return [_c("module-swap-rates")]
              },
              proxy: true,
            }
          : null,
        {
          key: _vm.name,
          fn: function () {
            return [_c("wallet-carousel")]
          },
          proxy: true,
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }