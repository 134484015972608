var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "core-components--app-addr-qr" }, [
    _c("div", { staticClass: "mew-heading-2 mb-3" }, [
      _vm._v(" " + _vm._s(_vm.$t("interface.qr.title")) + " "),
    ]),
    _c("div", { staticClass: "textDark--text mb-8" }, [
      _vm._v(
        " " +
          _vm._s(_vm.$t("interface.qr.desc", { network: _vm.getNetwork() })) +
          " "
      ),
    ]),
    _c(
      "div",
      { staticClass: "d-flex align-center mb-4" },
      [
        _c("mew-blockie", {
          attrs: { address: _vm.address, width: "22px", height: "22px" },
        }),
        _c("div", { staticClass: "pl-1 mew-body font-weight-bold" }, [
          _vm._v(" " + _vm._s(_vm.$t("interface.qr.my-main-account")) + " "),
        ]),
      ],
      1
    ),
    _c("div", { staticClass: "wallet-card-container" }, [
      _c("img", {
        staticClass: "wallet-card",
        attrs: {
          src:
            "https://mewcard.mewapi.io/?address=" +
            _vm.get0xAddress(_vm.address),
          alt: "MEW Card",
        },
        on: {
          load: function ($event) {
            return _vm.animateMewCard()
          },
        },
      }),
      _c(
        "div",
        { staticClass: "inner-content pa-3 pa-sm-8 d-flex align-center" },
        [
          _c(
            "div",
            {
              staticClass: "white pa-1",
              staticStyle: { "border-radius": "7px" },
            },
            [
              _c("app-qr-code", {
                attrs: {
                  data: _vm.getXDCAddress(_vm.address),
                  height: 132,
                  width: 132,
                },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "pl-3" }, [
            _c(
              "div",
              {
                staticClass:
                  "d-block monospace textDark-text container-qr--addr BalanceCardAddress",
              },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      this.prefix +
                        _vm.getXDCAddress(_vm.getChecksumAddressString).slice(3)
                    ) +
                    " "
                ),
              ]
            ),
            _c(
              "div",
              {
                staticClass:
                  "d-inline-flex align-center cursor--pointer mt-4 pa-1",
                on: { click: _vm.copyAddress },
              },
              [
                _c("v-icon", { attrs: { small: "", color: "white" } }, [
                  _vm._v("mdi-content-copy"),
                ]),
                _c("div", { staticClass: "ml-1 white--text" }, [
                  _vm._v("Copy"),
                ]),
              ],
              1
            ),
          ]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }