var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "40",
        height: "40",
        viewBox: "0 0 40 40",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("rect", {
        attrs: {
          x: "10",
          y: "15",
          width: "20",
          height: "2",
          rx: "1",
          fill: "black",
        },
      }),
      _c("rect", {
        attrs: {
          x: "10",
          y: "23",
          width: "20",
          height: "2",
          rx: "1",
          fill: "black",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }