import { render, staticRenderFns } from "./mainApp.vue?vue&type=template&id=491826ab"
import script from "./mainApp.vue?vue&type=script&lang=js"
export * from "./mainApp.vue?vue&type=script&lang=js"
import style0 from "./mainApp.vue?vue&type=style&index=0&id=491826ab&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/XDCWallet/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('491826ab')) {
      api.createRecord('491826ab', component.options)
    } else {
      api.reload('491826ab', component.options)
    }
    module.hot.accept("./mainApp.vue?vue&type=template&id=491826ab", function () {
      api.rerender('491826ab', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/main/mainApp.vue"
export default component.exports