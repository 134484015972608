var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "header", style: { top: _vm.topOffset + "px" } },
    [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12" }, [
            _c(
              "div",
              {
                class: [
                  _vm.inAccessOrCreate ? "fixed" : "",
                  "header__wrapper d-flex align-items-center justify-content-between js-header",
                ],
              },
              [
                _c(
                  "a",
                  { staticClass: "header__logo", attrs: { href: "/" } },
                  [
                    _vm.showDark
                      ? _c("logo-component")
                      : _c("img", {
                          attrs: {
                            src: require("@/assets/images/icons/logo-mew.png"),
                            width: "113px",
                            height: "auto",
                          },
                        }),
                  ],
                  1
                ),
                _c(
                  "a",
                  {
                    staticClass: "header__access-button",
                    style: _vm.showAccess,
                    attrs: { href: "/#/wallet/access" },
                  },
                  [_vm._v(" Access my wallet ")]
                ),
                _c(
                  "a",
                  {
                    staticClass: "header__mobile-menu-open",
                    attrs: { href: "javascript:void(0)" },
                    on: { click: _vm.openMobileMenu },
                  },
                  [_c("open-menu")],
                  1
                ),
              ]
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }