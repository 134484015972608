var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-menu",
    {
      attrs: {
        "max-height": "520",
        "content-class": "mew-dropdown-content-class",
        "offset-y": "",
        "close-on-content-click": false,
        left: "",
      },
      scopedSlots: _vm._u(
        [
          {
            key: "activator",
            fn: function (ref) {
              var on = ref.on
              return [
                _c(
                  "v-btn",
                  _vm._g(
                    {
                      staticClass: "py-2 px-4",
                      attrs: {
                        elevation: "0",
                        color: "rgba(109, 137, 166, 0.08)",
                        height: "36",
                      },
                    },
                    on
                  ),
                  [_vm._t("activatorBtnText")],
                  2
                ),
              ]
            },
          },
        ],
        null,
        true
      ),
      model: {
        value: _vm.isDropdownOpen,
        callback: function ($$v) {
          _vm.isDropdownOpen = $$v
        },
        expression: "isDropdownOpen",
      },
    },
    [
      _c(
        "v-card",
        { staticClass: "pa-0", attrs: { width: "380", color: "white" } },
        [
          _c(
            "v-card-title",
            { staticClass: "pl-8 pt-8 pb-0 rounded-lg" },
            [
              _vm.popupTitle
                ? _c("div", { staticClass: "mew-heading-2 break-word" }, [
                    _vm._v(" " + _vm._s(_vm.popupTitle) + " "),
                  ])
                : _vm._e(),
              _c(
                "v-btn",
                {
                  staticClass: "header-close-icon",
                  attrs: { icon: "", color: "rgba(32, 33, 36, 0.4)" },
                },
                [
                  _c(
                    "v-icon",
                    {
                      attrs: {
                        size: "x-large",
                        color: "rgba(32, 33, 36, 0.4);",
                      },
                      on: { click: _vm.close },
                    },
                    [_vm._v(" mdi-close ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "pa-8 full-width full-height" },
            [_vm._t("cardContent")],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }