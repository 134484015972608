var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "mew-token-container d-flex align-center justify-center",
      class: _vm.tokenBorder ? "token-border" : "",
      style: { height: "" + _vm.getSize, width: "" + _vm.getSize },
    },
    [
      _vm.loading
        ? _c("v-skeleton-loader", {
            staticClass: "token-skeleton",
            attrs: { height: _vm.getSize, width: _vm.getSize, type: "avatar" },
          })
        : _vm._e(),
      !_vm.loading && _vm.img
        ? _c("img", {
            style: { height: "" + _vm.getSize, width: "" + _vm.getSize },
            attrs: {
              src: _vm.img || _vm.ethTokenPlaceholder,
              alt: _vm.name,
              loading: "lazy",
            },
          })
        : _vm._e(),
      !_vm.loading && !_vm.img
        ? _c(
            "span",
            {
              class: [
                "d-flex align-center justify-center full-height textLight--text text-uppercase font-weight-medium",
                _vm.getFontClass,
              ],
            },
            [_vm._v(" " + _vm._s(_vm.getPlaceholderText) + " ")]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }