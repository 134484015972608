var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-text-field", {
    staticClass: "mew-input rounded-lg",
    attrs: {
      disabled: _vm.disabled,
      label: _vm.label,
      placeholder: _vm.placeholder,
      "error-messages": _vm.errorMessages,
      outlined: !_vm.hasNoBorder,
      solo: _vm.hasNoBorder,
      color: "primary",
      autofocus: _vm.autofocus,
      hint: _vm.resolvedAddr ? _vm.resolvedAddr : _vm.hint,
      "persistent-hint": _vm.persistentHint || _vm.resolvedAddr.length > 0,
      suffix: _vm.rightLabel,
      clearable: !_vm.hideClearBtn,
      rules: _vm.rules,
      type: _vm.inputType,
      "append-icon": _vm.showPasswordIcon,
      readonly: _vm.isReadOnly,
      "validate-on-blur": "",
      height: "62",
    },
    on: { "click:append": _vm.onPasswordIconClick },
    nativeOn: {
      keydown: function ($event) {
        return _vm.preventCharE($event)
      },
    },
    scopedSlots: _vm._u(
      [
        {
          key: "message",
          fn: function (item) {
            return [
              _c("span", { staticClass: "mew-label" }, [
                _vm._v(_vm._s(item.message) + " "),
                _vm.buyMoreStr
                  ? _c(
                      "a",
                      {
                        staticClass: "mew-label",
                        attrs: { rel: "noopener noreferrer" },
                        on: { click: _vm.emitBuyMore },
                      },
                      [_vm._v(_vm._s(_vm.buyMoreStr))]
                    )
                  : _vm._e(),
              ]),
            ]
          },
        },
        {
          key: "prepend-inner",
          fn: function () {
            return [
              _vm.showBlockie && !_vm.value
                ? _c("div", {
                    staticClass: "blockie-placeholder mr-1 selectHover",
                  })
                : _vm._e(),
              _vm.showBlockie && _vm.value
                ? _c("mew-blockie", {
                    attrs: {
                      address: _vm.resolvedAddr ? _vm.resolvedAddr : _vm.value,
                      width: "25px",
                      height: "25px",
                    },
                  })
                : _vm._e(),
              _c(
                "div",
                { staticClass: "d-flex align-center justify-center" },
                [
                  _vm._t("prependInnerIcon"),
                  _vm.image
                    ? _c("mew-token-container", {
                        staticClass: "mx-1 mt-1",
                        attrs: { img: _vm.image, alt: "image", size: "small" },
                      })
                    : _vm._e(),
                ],
                2
              ),
            ]
          },
          proxy: true,
        },
        {
          key: "append",
          fn: function () {
            return [
              _vm.maxBtnObj.method
                ? _c(
                    "v-btn",
                    {
                      class: [
                        _vm.maxBtnObj.disabled
                          ? "disabled--text no-pointer-events"
                          : "textDarkWhite--text",
                        "rounded-lg mt-n2 mew-caption font-weight-medium",
                      ],
                      attrs: {
                        "min-width": "40",
                        "min-height": "40",
                        height: "40",
                        width: "40",
                        depressed: "",
                        loading: _vm.maxBtnObj.loading,
                        color: "maxButton",
                      },
                      on: { click: _vm.maxBtnObj.method },
                    },
                    [_vm._v(" " + _vm._s(_vm.maxBtnObj.title) + " ")]
                  )
                : _vm._e(),
            ]
          },
          proxy: true,
        },
      ],
      null,
      true
    ),
    model: {
      value: _vm.inputValue,
      callback: function ($$v) {
        _vm.inputValue = $$v
      },
      expression: "inputValue",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }