var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "mew-overlay",
    {
      attrs: {
        footer: {
          text: "Need help?",
          linkTitle: "Contact support",
          link: "mailto:support@myetherwallet.com",
        },
        "show-overlay": _vm.onRegister,
        title: _vm.$t("rns.register-domain"),
        "content-size": "xlarge",
        close: _vm.close,
      },
    },
    [
      _c("mew-stepper", {
        attrs: { items: _vm.stepperItems, "on-step": _vm.onStep },
        scopedSlots: _vm._u([
          {
            key: "stepperContent1",
            fn: function () {
              return [
                _vm.onStep === 1
                  ? _c("request", {
                      staticClass: "mt-3",
                      attrs: {
                        name: _vm.name,
                        loading: _vm.checkingDomainAvail,
                        "get-rent-price": _vm.getRentPrice,
                      },
                      on: { onRequest: _vm.onRequest },
                    })
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
          {
            key: "stepperContent2",
            fn: function () {
              return [
                _vm.onStep === 2
                  ? _c("register", {
                      staticClass: "mt-3",
                      attrs: {
                        name: _vm.name,
                        duration: _vm.duration,
                        register: _vm.register,
                        "not-enough-funds": _vm.notEnoughFunds,
                        "no-funds-for-reg-fees": _vm.noFundsForRegFees,
                        "commit-fee-in-eth": _vm.commitFeeInEth,
                        "commit-fee-in-wei": _vm.commitFeeInWei,
                        "commit-fee-usd": _vm.commitFeeUsd,
                        "waiting-for-reg": _vm.waitingForReg,
                        commit: _vm.commit,
                        committed: _vm.committed,
                        "minimum-age": _vm.minimumAge,
                        "loading-commit": _vm.loadingCommit,
                        "loading-reg": _vm.loadingReg,
                      },
                    })
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
          {
            key: "stepperContent3",
            fn: function () {
              return [_vm.onStep === 3 ? _c("complete") : _vm._e()]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }