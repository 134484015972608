var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mew-component-mew-copy" },
    [
      _c(
        "v-tooltip",
        {
          attrs: {
            "content-class": "tooltip-inner",
            color: "textMedium--text",
            top: "",
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function (ref) {
                var on = ref.on
                return [
                  _c(
                    "v-icon",
                    _vm._g(
                      {
                        class: ["copy-icon cursor-pointer", _vm.className],
                        attrs: { small: _vm.isSmall, color: "textMedium" },
                        on: { click: _vm.copyToClipboard },
                      },
                      on
                    ),
                    [_vm._v(" mdi-content-copy ")]
                  ),
                ]
              },
            },
          ]),
        },
        [_c("span", [_vm._v(_vm._s(_vm.tooltip))])]
      ),
      _c("mew-toast", {
        ref: "toast",
        attrs: {
          duration: 2000,
          "toast-type": "success",
          text: _vm.successToast,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }