var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "32",
        height: "32",
        viewBox: "0 0 32 32",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("g", { attrs: { "clip-path": "url(#clip0_706_11125)" } }, [
        _c("circle", {
          attrs: {
            cx: "16",
            cy: "16",
            r: "16",
            fill: "black",
            "fill-opacity": "0.07",
          },
        }),
        _c("path", {
          attrs: {
            "fill-rule": "evenodd",
            "clip-rule": "evenodd",
            d: "M21.7071 11.7071C22.0976 11.3166 22.0976 10.6834 21.7071 10.2929C21.3166 9.90237 20.6834 9.90237 20.2929 10.2929L16 14.5858L11.7071 10.2929C11.3166 9.90238 10.6834 9.90238 10.2929 10.2929C9.90237 10.6834 9.90237 11.3166 10.2929 11.7071L14.5858 16L10.2929 20.2929C9.90237 20.6834 9.90237 21.3166 10.2929 21.7071C10.6834 22.0976 11.3166 22.0976 11.7071 21.7071L16 17.4142L20.2929 21.7071C20.6834 22.0976 21.3166 22.0976 21.7071 21.7071C22.0976 21.3166 22.0976 20.6834 21.7071 20.2929L17.4142 16L21.7071 11.7071Z",
            fill: "black",
          },
        }),
      ]),
      _c("defs", [
        _c("clipPath", { attrs: { id: "clip0_706_11125" } }, [
          _c("rect", { attrs: { width: "32", height: "32", fill: "white" } }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }